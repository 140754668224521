import { atom, selector } from 'recoil';
import { PaginatorInfo, Reward, initialPageInfo } from '../types';

export const rewardTxInfoState = atom<{
  partnerRewards: Reward.RewardSwapsRes;
  partnerRewardAmount: Reward.RewardAmountRes;
} | null>({
  key: 'rewardTxState/rewardTxInfo',
  default: null,
});

export const dataListState = selector<Reward.Data[]>({
  key: 'rewardTxState/dataList',
  get: ({ get }) => {
    const infos = get(rewardTxInfoState);

    if (infos) {
      return infos.partnerRewards.data;
    } else {
      return [];
    }
  },
});

export const paginatorInfoState = selector<PaginatorInfo>({
  key: 'rewardTxState/paginatorInfo',
  get: ({ get }) => {
    const infos = get(rewardTxInfoState);

    if (infos) {
      return infos.partnerRewards.paginatorInfo;
    } else {
      return initialPageInfo;
    }
  },
});

export const summaryInfoState = selector<Reward.RewardAmountRes>({
  key: 'rewardTxState/summaryInfo',
  get: ({ get }) => {
    const infos = get(rewardTxInfoState);

    if (infos) {
      return infos.partnerRewardAmount;
    } else {
      return {
        total_count: 0,
        data: [],
      };
    }
  },
});
