import { ApolloClient, DefaultOptions, InMemoryCache, from } from '@apollo/client';
import httpLink from './httpLink';
import errorLink from './errorLink';
import authLink from './authLink';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
  },
  query: {
    fetchPolicy: 'network-only',
  },
  mutate: {},
};

const client = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
  credentials: 'include',
  defaultOptions: defaultOptions,
});

export default client;
