import React from 'react';

const useKeyDown = (callback: (T?: any) => void, keys: KeyboardKey[]) => {
  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const wasAnyKeyPressed = keys.some((key) => event.key === key);

    if (wasAnyKeyPressed) {
      event.preventDefault();
      callback();
    }
  };

  return onKeyDown;
};

export default useKeyDown;

export enum KeyboardKey {
  enter = 'Enter',
}
