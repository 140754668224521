import { atom, selector } from 'recoil';
import { Charge, PaginatorInfo, initialPageInfo } from '@/types/index';

export const chargeTxInfoState = atom<{
  partnerImportSwapAmount: Charge.ImportSwapAmountRes;
  partnerImportSwaps: Charge.ImportSwapsRes;
} | null>({
  key: 'chargeTxState/chargeTxInfo',
  default: null,
});

export const dataListState = selector<Charge.Data[]>({
  key: 'chargeTxState/dataList',
  get: ({ get }) => {
    const infos = get(chargeTxInfoState);

    if (infos) {
      return infos.partnerImportSwaps.data;
    } else {
      return [];
    }
  },
});

export const paginatorInfoState = selector<PaginatorInfo>({
  key: 'chargeTxState/paginatorInfo',
  get: ({ get }) => {
    const infos = get(chargeTxInfoState);

    if (infos) {
      return infos.partnerImportSwaps.paginatorInfo;
    } else {
      return initialPageInfo;
    }
  },
});

export const summaryInfoState = selector<Charge.ImportSwapAmountRes>({
  key: 'chargeTxState/summaryInfo',
  get: ({ get }) => {
    const infos = get(chargeTxInfoState);

    if (infos) {
      return infos.partnerImportSwapAmount;
    } else {
      return {
        total_count: 0,
        data: [],
        coinSwap: {
          id: '',
          exportCoin: {
            id: '',
            acronym: '',
            unit: '',
          },
          importCoin: {
            id: '',
            acronym: '',
            unit: '',
          },
        },
      };
    }
  },
});
