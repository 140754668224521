import React, { useMemo, useRef, useState } from 'react';
import { Box, Flex, Button } from '@chakra-ui/react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title as ChartTitle,
  Tooltip,
  Legend,
  BarElement,
  LineController,
} from 'chart.js';
import { useQuery } from '@apollo/client';
import { MONTHLY_STATISTICS } from '@graphql/statistics';
import TitleSelect from '@atoms/TitleSelect';
import { chartOptions, initialChartData } from '@const/chart';
import { getYearOptions, makeChartData, makeLabel } from '@utils/chart';
import { useMediaQuery } from '@chakra-ui/media-query';
import { BgWrapper } from '@layout/index';
import { Statistics } from '@/types/index';

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  BarElement,
  LineElement,
  ChartTitle,
  Tooltip,
  Legend,
);

interface Props {
  pageId: number;
}

const DashBoardChart: React.FC<Props> = ({ pageId }) => {
  const years = getYearOptions(2022);
  const chartRef = useRef(null);

  const [selectedYear, setSelectedYear] = useState(years[0].key);
  const [isMediumScreen] = useMediaQuery('(max-width: 1500px)');
  const [chartLabels, setChartLabels] = useState<string[]>([]);
  const [chartDataInfos, setChartDataInfos] = useState<Statistics.ChartInfo>({});
  const [currentChart, setCurrentChart] = useState<string>('');

  const dataset: any = useMemo(() => {
    if (!chartDataInfos[currentChart]) return initialChartData;
    const labels = makeLabel(selectedYear);
    const { importAmounts, rewardAmounts, feeAmounts } = chartDataInfos[currentChart];

    return makeChartData(importAmounts, rewardAmounts, feeAmounts, labels);
  }, [chartDataInfos, currentChart, selectedYear]);

  useQuery<{ coinSwapMonthlyAggregate: Statistics.MonthlyChartRes[] }>(MONTHLY_STATISTICS, {
    variables: {
      year: Number(selectedYear),
      pageId,
    },
    onCompleted: ({ coinSwapMonthlyAggregate }) => {
      const data: Statistics.ChartInfo = {};

      coinSwapMonthlyAggregate.forEach((el) => {
        const name = `${el.coinSwap.exportCoin.acronym} > ${el.coinSwap.importCoin.acronym}`;
        const dataByMonth: { [key: number]: Statistics.MonthlyChartData } = {};
        el.aggregate.forEach((el: Statistics.MonthlyChartData) => {
          dataByMonth[el.month] = el;
        });
        const importAmounts: number[] = [];
        const rewardAmounts: number[] = [];
        const feeAmounts: number[] = [];

        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].forEach((month) => {
          if (dataByMonth[month]) {
            importAmounts[month - 1] = Number(dataByMonth[month].import_amount);
            rewardAmounts[month - 1] = Number(dataByMonth[month].reward);
            feeAmounts[month - 1] = Number(dataByMonth[month].fee);
          } else {
            importAmounts[month - 1] = 0;
            rewardAmounts[month - 1] = 0;
            feeAmounts[month - 1] = 0;
          }
        });

        data[name] = {
          importAmounts,
          rewardAmounts,
          feeAmounts,
        };
      });

      setChartLabels(Object.keys(data));
      setCurrentChart(Object.keys(data)[0]);
      setChartDataInfos(data);
    },
  });

  return (
    <BgWrapper mb={24} mt={8}>
      <Flex justifyContent={'center'} position="relative" mt={6} mb={6}>
        <Box position={'absolute'} right={isMediumScreen ? -5 : 0} top={isMediumScreen ? -24 : -6}>
          <TitleSelect
            title="연도"
            onChange={(value) => setSelectedYear(value)}
            options={years}
            selectedValue={selectedYear}
          />
        </Box>
        <Flex
          flexDir="column"
          w="70%"
          gap={16}
          justifyContent={'flex-start'}
          alignItems="flex-start"
        >
          <Flex gap={4}>
            {chartLabels.map((name) => {
              return (
                <Button
                  size="sm"
                  key={name}
                  colorScheme={name === currentChart ? 'teal' : 'gray'}
                  onClick={() => setCurrentChart(name)}
                >
                  {name}
                </Button>
              );
            })}
          </Flex>
          <Bar options={chartOptions} data={dataset} ref={chartRef} />
        </Flex>
      </Flex>
    </BgWrapper>
  );
};

export default DashBoardChart;
