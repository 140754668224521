import React from 'react';
import { useRecoilValue } from 'recoil';
import { Statistics } from '@/types/index';
import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { walletStatistics } from '@store/dashBoardState';
import { addCommas } from '@utils/index';
import { TEAL_BORDER } from '@const/style';

const ConnectedWalletInfo = () => {
  const data = useRecoilValue(walletStatistics);

  return (
    <Flex
      mt={6}
      borderRadius={4}
      backgroundColor="white"
      overflowX="scroll"
      border={TEAL_BORDER}
      pr={8}
      py={3}
      gap={1}
    >
      <Stack minW={36} textAlign={'center'}>
        <Box flex={1} fontWeight="bold" mb={2} color="transparent"></Box>
        <Text flex={1} fontSize="md" fontWeight="extrabold">
          당일
        </Text>
        <Text flex={1} fontSize="md" fontWeight="extrabold">
          어제
        </Text>
        <Text flex={1} fontSize="md" fontWeight="extrabold">
          누적
        </Text>
      </Stack>
      {data.map((info: Statistics.WalletCountRes) => {
        return (
          <Stack key={info.coin.id} borderLeft="none" flex={1} minW={48} textAlign="right">
            <Text fontWeight="bold" pb={1} mb={4} borderBottom={TEAL_BORDER}>
              {info.coin.acronym}
            </Text>
            <Text fontSize="md" fontWeight="extrabold" color="gray.600">
              {addCommas(info.today_count)} 개
            </Text>
            <Text fontSize="md" fontWeight="extrabold" color="gray.600">
              {addCommas(info.yesterday_count)} 개
            </Text>
            <Text fontSize="md" fontWeight="extrabold" color="gray.600">
              {addCommas(info.total_count)} 개
            </Text>
          </Stack>
        );
      })}
    </Flex>
  );
};

export default ConnectedWalletInfo;
