import { Th } from '@atoms/table';
import { Flex, Thead, Tr, Text } from '@chakra-ui/react';
import { TH_BG_COLOR } from '@const/style';
import { TableHeaderInfo } from '@const/table';
import React from 'react';
import { BiArrowFromLeft, BiArrowFromRight } from 'react-icons/bi';

interface Props {
  headerInfos: TableHeaderInfo[];
  shrinkList: string[];
  onDoubleClick: (value: string) => void;
}

const TableHeader: React.FC<Props> = ({ headerInfos, shrinkList, onDoubleClick }) => {
  return (
    <Thead bgColor={TH_BG_COLOR}>
      <Tr>
        {headerInfos.map((info) => {
          if (info.canShrink) {
            return (
              <Th key={info.key} onDoubleClick={() => onDoubleClick(info.key)}>
                <Flex justify={'center'} align={'center'}>
                  {shrinkList.includes(info.key) ? (
                    <BiArrowFromRight onClick={() => onDoubleClick(info.key)} />
                  ) : (
                    <BiArrowFromLeft onClick={() => onDoubleClick(info.key)} />
                  )}
                  <Text mx={2}>{info.label}</Text>
                  {shrinkList.includes(info.key) ? (
                    <BiArrowFromLeft onClick={() => onDoubleClick(info.key)} />
                  ) : (
                    <BiArrowFromRight onClick={() => onDoubleClick(info.key)} />
                  )}
                </Flex>
              </Th>
            );
          } else {
            return (
              <Th textTransform="none" key={info.key}>
                {info.label}
              </Th>
            );
          }
        })}
      </Tr>
    </Thead>
  );
};

export default TableHeader;
