import { Flex } from '@chakra-ui/react';
import { BgWrapper, ContentWrapper } from '@layout/index';
import Title from '@atoms/Title';
import ChargeTxTable from './components/ChargeTxTable';
import ChargeTxFilter from './components/ChargeTxFilter';
import ChargeTxSummary from './components/ChargeTxSummary';
import Loading from '@atoms/Loading';
import { useQuery } from '@apollo/client';
import { CHARGE_TX, makeSwapVariables } from '@graphql/transaction';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { chargeTxInfoState } from '@store/chargeTxState';
import { currentTableInfoFamily, tCoinOptionStatusFamily } from '@store/family/tableFamily';
import { Tables } from '@/types/index';
import useSearchOptions from '@hooks/useSearchOptions';

interface Props {
  pageId: number;
}

const ChargeTx: React.FC<Props> = ({ pageId }) => {
  const { coinOptions } = useSearchOptions(Tables.Charge);
  const tCoinOptionStatus = useRecoilValue(tCoinOptionStatusFamily(Tables.Charge));
  const currentSwapDir = coinOptions?.filter(
    (option) => Number(option.key) === Number(tCoinOptionStatus),
  )[0].value;

  const [infos, setInfos] = useRecoilState(chargeTxInfoState);
  const {
    tStartDate,
    tEndDate,
    tKeyword,
    tStatus,
    tCoinOption,
    tSearchOption,
    tPerPage,
    tCurrentPage,
  } = useRecoilValue(currentTableInfoFamily(Tables.Charge));

  useQuery(CHARGE_TX, {
    variables: makeSwapVariables({
      pageId: pageId,
      coinId: tCoinOption,
      startDate: tStartDate,
      endDate: tEndDate,
      keyword: tKeyword,
      status: tStatus,
      option: tSearchOption,
      perPage: tPerPage,
      page: tCurrentPage,
    }),
    skip: pageId === 0 || !tCoinOption,
    onCompleted: (data) => setInfos(data),
  });

  const isLoading = !infos || !pageId;
  if (isLoading) return <Loading isLoading={isLoading} />;

  return (
    <Flex px={8} flexDir="column" flex={1}>
      <Title title="충전 내역 관리" subTitle={currentSwapDir} />
      <ContentWrapper>
        <ChargeTxSummary />
        <BgWrapper>
          <ChargeTxFilter />
          <ChargeTxTable pageId={pageId} />
        </BgWrapper>
      </ContentWrapper>
    </Flex>
  );
};

export default ChargeTx;
