import { atom, selector } from 'recoil';
import { BillHistory, PaginatorInfo, initialPageInfo } from '@/types/index';

export const billHistoryInfoState = atom<{
  partnerSettlements: BillHistory.SettlementsRes;
  partnerSettlementAmount: BillHistory.SettlementAmountRes;
} | null>({
  key: 'billHistoryState/billHistoryInfo',
  default: null,
});

export const dataListState = selector<BillHistory.Data[]>({
  key: 'billHistoryState/dataList',
  get: ({ get }) => {
    const infos = get(billHistoryInfoState);

    if (infos) {
      return infos.partnerSettlements.data;
    } else {
      return [];
    }
  },
});

export const paginatorInfoState = selector<PaginatorInfo>({
  key: 'billHistoryState/paginatorInfo',
  get: ({ get }) => {
    const infos = get(billHistoryInfoState);

    if (infos) {
      return infos.partnerSettlements.paginatorInfo;
    } else {
      return initialPageInfo;
    }
  },
});

export const summaryInfoState = selector<BillHistory.SettlementAmountRes>({
  key: 'billHistoryState/summaryInfo',
  get: ({ get }) => {
    const infos = get(billHistoryInfoState);

    if (infos) {
      return infos.partnerSettlementAmount;
    } else {
      return {
        total_count: 0,
        data: [],
        coinSwap: {
          id: '',
          exportCoin: {
            id: '',
            acronym: '',
            unit: '',
          },
          importCoin: {
            id: '',
            acronym: '',
            unit: '',
          },
        },
      };
    }
  },
});
