import React, { useLayoutEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useLazyQuery } from '@apollo/client';
import { Flex, Button } from '@chakra-ui/react';
import { BILL_HISTORY } from '@graphql/billHistory';
import {
  currentPageFamily,
  tCoinOptionStatusFamily,
  tKeywordFamily,
  tMonthFamily,
  tOptionStatusFamily,
  tYearFamily,
} from '@store/family/tableFamily';
import useDateSelect from '@hooks/useDateSelect';
import { settlementDownloadHeader } from '@const/table';
import DateSelect from '@atoms/DateSelect';
import XLSDownloadButton from '@atoms/XLSDownloadButton';
import { parseDownloadBillData } from '@utils/parser';
import { Tables } from '@/types/index';
import { PRIMARY_SCHEME } from '@const/style';
import TitleSelect from '@atoms/TitleSelect';
import useSearchOptions from '@hooks/useSearchOptions';

interface Props {
  pageId: number;
}

const BillFilter: React.FC<Props> = ({ pageId }) => {
  const [tYear, setTYear] = useRecoilState(tYearFamily(Tables.Bill));
  const [tMonth, setTMonth] = useRecoilState(tMonthFamily(Tables.Bill));
  const [tCoinOption, setTCoinOption] = useRecoilState(tCoinOptionStatusFamily(Tables.Bill));
  const setCurrentPage = useSetRecoilState(currentPageFamily(Tables.Bill));
  const setTKeyword = useSetRecoilState(tKeywordFamily(Tables.Bill));
  const setTSearchOption = useSetRecoilState(tOptionStatusFamily(Tables.Bill));

  const [coinOptionStatus, setCoinOptionStatus] = useState('');

  const { searchOptions, coinOptions } = useSearchOptions(Tables.Bill);
  const { selectedYear, selectedMonth, handleYear, handleMonth } = useDateSelect(tYear, tMonth);
  const [download] = useLazyQuery(BILL_HISTORY);

  // 코인이 변경되면 서치옵션도 초기화 필요
  const handleCoinOptions = (value: string) => {
    const initialSearchOption = searchOptions[value][0].key;

    setCoinOptionStatus(value);
    setTSearchOption(initialSearchOption);
  };

  const onDownloadClick = async () => {
    const { data } = await download({
      variables: {
        year: tYear,
        month: tMonth,
        pageId: pageId,
        coinId: Number(tCoinOption),
      },
    });

    return parseDownloadBillData(data['partnerSettlementAmount']);
  };

  const onSearchClick = () => {
    setTYear(selectedYear);
    setTMonth(selectedMonth);
    setTCoinOption(coinOptionStatus);
    setCurrentPage(1);
    setTKeyword('');
  };

  useLayoutEffect(() => {
    setCoinOptionStatus(tCoinOption);
  }, [tCoinOption]);

  return (
    <Flex justify="space-between">
      <Flex gap={4}>
        <TitleSelect
          title="정산 스왑"
          onChange={handleCoinOptions}
          options={coinOptions}
          selectedValue={coinOptionStatus}
          w={64}
        />
        <DateSelect
          fromYear={2022}
          selectedYear={selectedYear}
          onChangeYear={handleYear}
          selectedMonth={selectedMonth}
          onChangeMonth={handleMonth}
        />
      </Flex>
      <Flex gap={4}>
        <XLSDownloadButton
          onDownloadClick={onDownloadClick}
          headers={settlementDownloadHeader()}
          fileName={`정산내역 ${tYear}/${tMonth}`}
          size="md"
        />
        <Button w={32} variant="solid" colorScheme={PRIMARY_SCHEME} onClick={onSearchClick}>
          조회
        </Button>
      </Flex>
    </Flex>
  );
};

export default BillFilter;
