import { atom, selector } from 'recoil';
import { Consumer, PaginatorInfo, initialPageInfo } from '@/types/index';

export const consumerInfoState = atom<{ userSearch: Consumer.UserSearchRes } | null>({
  key: 'consumerState/consumerInfo',
  default: null,
});

export const dataListState = selector<Consumer.UserSearchData[]>({
  key: 'consumerState/dataList',
  get: ({ get }) => {
    const infos = get(consumerInfoState);

    if (infos) {
      return infos.userSearch.data;
    } else {
      return [];
    }
  },
});

export const paginatorInfoState = selector<PaginatorInfo>({
  key: 'consumerState/paginatorInfo',
  get: ({ get }) => {
    const infos = get(consumerInfoState);

    if (infos) {
      return infos.userSearch.paginatorInfo;
    } else {
      return initialPageInfo;
    }
  },
});
