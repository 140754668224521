import { Box, Flex, Text } from '@chakra-ui/react';
import { PRIMARY_COLOR } from '@const/style';
import React from 'react';

interface Props {
  title: string;
  subTitle?: string;
  mt?: number;
}

const Title: React.FC<Props> = ({ title, subTitle, mt }) => {
  const text = subTitle ? (
    <Flex alignItems="center">
      <Text fontWeight="bold" mr={2}>
        {title}
      </Text>
      <Text fontWeight="normal" fontSize="sm">
        {' '}
        ({subTitle})
      </Text>
    </Flex>
  ) : (
    <Text fontWeight="bold">{title}</Text>
  );

  return (
    <Box mt={mt}>
      <Flex
        fontSize="xl"
        pl={3}
        borderLeft="4px solid"
        borderColor={PRIMARY_COLOR}
        color="blackAlpha.700"
      >
        {text}
      </Flex>
    </Box>
  );
};

export default Title;
