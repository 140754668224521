import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {
  getRefreshAvailAt,
  getToken,
  isOverExpiredAt,
  removeLoginInfo,
  setLoginInfo,
} from '@utils/index';
import httpLink from './httpLink';
import { REFRESH_TOKEN } from '@graphql/user';
import { isBefore, parseISO } from 'date-fns';
import { Paths } from '@pages/Router';

const renewTokenApiClient = new ApolloClient({
  link: from([httpLink]),
  cache: new InMemoryCache(),
  credentials: 'include',
});

const refreshToken = async () => {
  const token = getToken();

  const {
    data: {
      refreshToken: { access_token: accessToken, expires_in: expiresIn },
    },
  } = await renewTokenApiClient.mutate({
    mutation: REFRESH_TOKEN,
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  return { accessToken, expiresIn };
};

let isRefreshing = false; // 리프레시 중인지 여부를 추적하는 상태 변수
let requestQueue: any[] = []; // 대기열에 저장된 요청들

export const getIsRefreshing = () => isRefreshing;

export const initRefreshStatus = () => {
  isRefreshing = false;
  requestQueue = [];
};

const processRequestQueue = (newAccessToken: string) => {
  requestQueue.forEach(({ resolve, headers }) => {
    resolve({ headers: { ...headers, authorization: `Bearer ${newAccessToken}` } });
  });
  requestQueue = [];
};

const authLink = setContext(async (config, { headers }) => {
  const token = getToken();
  const isRefreshingAvailTime = isBefore(parseISO(getRefreshAvailAt()), new Date());

  // 토큰없는경우 | 사용자가 발생시킨 요청이 아닌경우
  if (!token || config.operationName === 'frontReleases') {
    return {
      headers: {
        ...headers,
        authorization: '',
      },
    };
  }

  if (isOverExpiredAt()) {
    alert('로그인이 만료 되었습니다.');
    removeLoginInfo();
    window.location.replace(Paths.Login);
  }

  if (!isRefreshingAvailTime) {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  }

  if (isRefreshingAvailTime && !isRefreshing) {
    isRefreshing = true;

    try {
      const response = await refreshToken();
      const { accessToken: newAccessToken, expiresIn } = response;
      setLoginInfo(newAccessToken, expiresIn);

      isRefreshing = false; // 리프레시 완료 후, 리프레시 중 상태 변수 초기화
      processRequestQueue(newAccessToken); // 대기열에 있는 요청들을 처리

      return {
        headers: {
          ...headers,
          authorization: `Bearer ${newAccessToken}`,
        },
      };
    } catch (error) {
      removeLoginInfo();
    }
  }

  if (isRefreshingAvailTime && isRefreshing) {
    return new Promise((resolve) => {
      requestQueue.push({ resolve, headers });
    });
  }
});

export default authLink;
