import { BillHistory, Charge, Consumer, Reward, Use } from '../types';

export const parseSwapStatus = (status: string): string => {
  switch (status) {
    case 'COMPLETED':
      return '완료';
    case 'CANCEL':
      return '취소';
    case 'PENDING':
      return '대기';
    case 'REQUEST':
      return '요청';
    default:
      return status;
  }
};

export const parseDownloadChargeData = (list: Charge.Data[]) => {
  return list.map((el: Charge.Data, index: number) => {
    return {
      number: list.length - index,
      email: el.email,
      partnerId: el.partner_id,
      orderNo: el.swap_hash,
      partnerOrderNo: el.partner_order_no,
      requestPoint: el.original_amount,
      chargePoint: el.import_amount,
      fee: el.fee,
      feeTmo: el.fee_tmo,
      feePartner: el.fee_partner,
      status: el.status,
      completedAt: el.created_at,
      canceledAt: el.canceled_at,
    };
  });
};

export const parseDownloadUseData = (list: Use.Data[]) => {
  return list.map((el: Use.Data, index: number) => {
    return {
      number: list.length - index,
      email: el.email,
      partnerId: el.partner_id,
      orderNo: el.swap_hash,
      partnerOrderNo: el.partner_order_no,
      usePoint: el.original_amount,
      getPoint: el.import_amount,
      fee: el.fee,
      feeTmo: el.fee_tmo,
      feePartner: el.fee_partner,
      tmoFee: el.fee_tmo,
      partnerFee: el.fee_partner,
      reward: el.reward,
      status: el.status,
      completedAt: el.created_at,
      canceledAt: el.canceled_at,
    };
  });
};

export const parseDownloadRewardData = (list: Reward.Data[]) => {
  return list.map((el: Reward.Data, index: number) => {
    return {
      number: list.length - index,
      email: el.email,
      partnerId: el.partner_id,
      orderNo: el.reward_hash,
      partnerOrderNo: el.partner_order_no,
      reward: el.amount,
      rewardStatus: el.status,
      completedAt: el.created_at,
      canceledAt: el.canceled_at,
    };
  });
};

export const parseDownloadBillData = (data: BillHistory.Summary) => {
  return [
    {
      exportAmount: data.export_amount,
      importAmount: data.import_amount,
      rewardAmount: data.reward,
      totalFee: data.fee,
      tmopFee: data.fee_tmo,
      partnerFee: data.fee_partner,
    },
  ];
};

export const parseDownloadUserInfoData = (list: Consumer.UserSearchData[]) => {
  return list.map((el: Consumer.UserSearchData, index: number) => {
    return {
      number: list.length - index,
      email: el.email,
      id: el.id,
      celebe_uid: el.celebe_uid,
      celebe_nickname: el.celebe_nickname,
      country: el.userGeoip?.country,
      language: el.locale,
      createdAt: el.created_at,
      deletedAt: el.deleted_at,
    };
  });
};
