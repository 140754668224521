import { Box, Button } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { LabelKeyObject } from 'react-csv/components/CommonPropTypes';

interface Props {
  onDownloadClick: () => Promise<any[]>;
  headers: LabelKeyObject[];
  fileName: string;
  size?: string;
}

const XLSDownloadButton: React.FC<Props> = ({
  onDownloadClick,
  headers,
  fileName,
  size = 'sm',
}) => {
  const [downloadData, setDownloadData] = useState<any[]>([]);
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null); // setup the ref that we'll use for the hidden CsvLink click once we've updated the data
  const fileNameWithDate = `${fileName}_${format(new Date(), 'yyMMdd:hhmmss')}`;

  const onClick = async () => {
    const download = await onDownloadClick();
    setDownloadData(download);
  };

  useEffect(() => {
    if (downloadData.length > 0) {
      csvLinkRef?.current?.link.click();
      setDownloadData([]);
    }
  }, [downloadData]);

  return (
    <Wrapper>
      <Button size={size} variant="outline" onClick={onClick}>
        XLS 다운로드
      </Button>
      <CSVLink
        className="hidden"
        data={downloadData}
        filename={fileNameWithDate}
        ref={csvLinkRef}
        headers={headers}
      ></CSVLink>
    </Wrapper>
  );
};

export default XLSDownloadButton;

const Wrapper = styled(Box)`
  & .hidden {
    display: none;
  }
`;
