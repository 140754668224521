import { useState } from 'react';
import { SelectOption, Tables } from '../types';
import { useRecoilState } from 'recoil';
import { tCoinOptionStatusFamily, tOptionStatusFamily } from '@store/family/tableFamily';
import { useQuery } from '@apollo/client';
import { SEARCH_OPTIONS } from '@graphql/options';
import { useCurrentPageId } from '@store/userState';
import { userInfoSearchOptions } from '@const/filter';

interface SearchOptionResponse {
  coin_id: number;
  name: string;
  option: SelectOption[];
}

type OptionType = {
  [key in string]: SelectOption[];
};

/* 
  searchOption은 coinOption에 의존한다.
  searchOption 의 키는 coinOption에 의해 선택된 coinId 이다.
  같은 coinId 여도 table에 따라 searchOption에 따라 다르다. 그래서 _table을 받아서 searchOption객체를 생성
  coinOption이 없는 테이블은 Table 이름을 키 값으로 한다
*/

function useSearchOptions(_table: Tables): {
  searchOptions: OptionType;
  coinOptions: SelectOption[];
} {
  const { pageId } = useCurrentPageId();
  const [tCoinOptionStatus, setTCoinOptionStatus] = useRecoilState(tCoinOptionStatusFamily(_table));
  const [tSearchOptionStatus, setTSearchOptionStatus] = useRecoilState(tOptionStatusFamily(_table));

  const [searchOptions, setSearchOptions] = useState<OptionType>({});
  const [coinOptions, setCoinOptions] = useState<OptionType>({});

  useQuery(SEARCH_OPTIONS, {
    variables: { pageId },
    skip: pageId === 0,
    onCompleted: ({ partnerSearchOption }) => {
      const importCoinOption = partnerSearchOption.import.map((info: SearchOptionResponse) => {
        return { key: info.coin_id, value: `${info.name} > TMOP` };
      });

      const exportCoinOption = partnerSearchOption.export.map((info: SearchOptionResponse) => {
        return { key: info.coin_id, value: `TMOP > ${info.name}` };
      });

      const rewardCoinOption = partnerSearchOption.reward.map((info: SearchOptionResponse) => {
        return { key: info.coin_id, value: info.name };
      });

      const coinOptions = {
        [Tables.Charge]: importCoinOption,
        [Tables.Use]: exportCoinOption,
        [Tables.Reward]: rewardCoinOption,
        [Tables.Bill]: exportCoinOption,
        [Tables.User]: [{ key: Tables.User, value: '' }],
      };

      const searchOptionsById: OptionType = {};
      if (_table === Tables.Use || _table === Tables.Bill) {
        partnerSearchOption[Tables.Use].forEach((info: SearchOptionResponse) => {
          searchOptionsById[info.coin_id] = info.option;
        });
      } else if (_table === Tables.Charge || _table === Tables.Reward) {
        partnerSearchOption[_table].forEach((info: SearchOptionResponse) => {
          searchOptionsById[info.coin_id] = info.option;
        });
      } else if (_table === Tables.User) {
        searchOptionsById[_table] = userInfoSearchOptions;
      }

      if (!tSearchOptionStatus) {
        const initialCoinKey = coinOptions[_table][0].key;
        const initialSearchKey = searchOptionsById[initialCoinKey][0].key;

        setTCoinOptionStatus(initialCoinKey);
        setTSearchOptionStatus(initialSearchKey);
      }

      // 하나의 데이터를 coinOptions, searchOption 으로 normalize 이후에 반환
      setCoinOptions(coinOptions);
      setSearchOptions(searchOptionsById);
    },
    fetchPolicy: 'cache-first',
  });

  return { searchOptions, coinOptions: coinOptions[_table] };
}

export default useSearchOptions;
