import { LATEST_CHECK_TIME } from '@const/index';
import { format } from 'date-fns';

export function addCommas(_number: number | null): string {
  if (!_number) return '0';
  const parts = _number.toString().split('.');
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const decimalPart = parts[1] ? `.${parts[1]}` : '';
  return integerPart + decimalPart;
}

export function makeCreatedAtObj(_startDate: Date | null, _endDate: Date | null) {
  if (!_startDate || !_endDate) return null;
  return {
    from: format(_startDate, 'yyyy-MM-dd 00:00:00'),
    to: format(_endDate, 'yyyy-MM-dd 23:59:59'),
  };
}

export function createYearArray(fromYear: number): number[] {
  const currentYear = new Date().getFullYear();
  const yearArray = [];

  for (let year = fromYear; year <= currentYear; year++) {
    yearArray.push(year);
  }

  return yearArray.reverse();
}

export function createMonthArray(_selectedYear: number): number[] {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // JavaScript의 월은 0부터 시작하므로 1을 더해줍니다.

  const monthArray = [];

  for (let month = 1; month <= 12; month++) {
    if (currentYear === _selectedYear) {
      if (month <= currentMonth) {
        monthArray.push(month);
      }
    } else {
      monthArray.push(month);
    }
  }

  return monthArray.reverse();
}

export const getLatestReleaseCheckTime = () => localStorage.getItem(LATEST_CHECK_TIME);
export const setLatestReleaseCheckTime = (date: string) =>
  localStorage.setItem(LATEST_CHECK_TIME, date);

// pathname 내의 number -> ":id" 로 변경
export const replaceNumberToStringId = (pathname: string) => {
  return pathname.replace(/\d+/g, ':id');
};

// Paths 내의 하위 pathname을 allowedUrls에 추가
export const getAllowedUrls = (
  pathsObject: { [s: string]: string } | ArrayLike<string>,
  allowedUrlsArray: string[],
) => {
  let totalAllowedUrls = [...allowedUrlsArray];
  Object.values(pathsObject).forEach((path) => {
    const parentPath = path.substring(0, path.lastIndexOf('/'));
    if (allowedUrlsArray.includes(parentPath)) {
      const matchingPaths = Object.values(pathsObject)
        .filter((p) => p.startsWith(parentPath))
        .filter((p) => !totalAllowedUrls.includes(p));
      totalAllowedUrls.push(...matchingPaths);
    }
  });
  return totalAllowedUrls;
};

export * from './parser';
export * from './token';
export * from './tables';
