import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { ME } from '@graphql/user';
import { Paths } from '@pages/Router';
import { isLoggedInSelector, userInfoState } from '@store/userState';
import { getToken, isOverExpiredAt, removeLoginInfo } from '@utils/token';

// 토큰 유무, 만료시간, 권한관련정보 보유 유무에 따른 처리 함수
const LoginHandler = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isLoggedIn = useRecoilValue(isLoggedInSelector);
  const setUserCoinInfo = useSetRecoilState(userInfoState);
  const [getUserInfo] = useLazyQuery(ME, { onCompleted: ({ me }) => setUserCoinInfo(me) });

  useEffect(() => {
    // 엑세스토큰 없는경우 블락킹 리다이렉트
    if (!getToken()) {
      navigate(Paths.Login);
    }

    // 리프레시 기간 만료된경우 로그아웃 처리
    if (pathname !== Paths.Login) {
      if (isOverExpiredAt()) {
        alert('토큰이 만료 되었습니다.');
        removeLoginInfo();
        navigate(Paths.Login);
      }
    }

    if (!isLoggedIn && getToken()) {
      getUserInfo();
    }
  }, [getUserInfo, isLoggedIn, navigate, pathname]);
};

export default LoginHandler;
