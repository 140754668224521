import { AlertModalProps } from '@components/modals/AlertModal';
import { ConfirmModalProps } from '@components/modals/ConfirmModal';
import { atom } from 'recoil';

export const modalState = atom<ModalProps[]>({
  key: 'modalState',
  default: [],
});
export interface BasicModalProps {
  modalKey: string;
  type: string;
}
//TODO: 수정필요
export type ModalProps = AlertModalProps | ConfirmModalProps;
