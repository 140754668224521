export interface TableHeaderInfo {
  label: string;
  key: string;
  canShrink?: boolean;
}

export const chargeDownloadHeader = (partnerName = '파트너사'): TableHeaderInfo[] => {
  return [
    { label: '번호', key: 'number' },
    { label: '팬시 월렛 ID', key: 'email' },
    { label: `${partnerName} ID`, key: 'partnerId' },
    { label: '거래번호(팬시월렛)', key: 'orderNo', canShrink: true },
    { label: `거래번호(${partnerName})`, key: 'partnerOrderNo' },
    { label: `${partnerName} 수량`, key: 'requestPoint' },
    { label: 'TMOP 수량', key: 'chargePoint' },
    { label: '수수료', key: 'fee' },
    { label: '티모 수수료', key: 'feeTMO' },
    { label: `${partnerName} 수수료`, key: 'feePartner' },
    { label: '상태', key: 'status' },
    { label: '완료 일시', key: 'completedAt' },
    { label: '취소 일시', key: 'canceledAt' },
  ];
};

export const txUseDownloadHeader = (partnerName = '파트너사'): TableHeaderInfo[] => {
  return [
    { label: '번호', key: 'number' },
    { label: '티모 월렛 ID', key: 'email' },
    { label: `${partnerName} ID`, key: 'partnerId' },
    { label: '거래번호(티모월렛)', key: 'orderNo', canShrink: true },
    { label: `거래번호(${partnerName})`, key: 'partnerOrderNo' },
    { label: 'TMOP 수량', key: 'usePoint' },
    { label: `${partnerName} 수량`, key: 'getPoint' },
    { label: '수수료', key: 'fee' },
    { label: '팬시 수수료', key: 'feeTmo' },
    { label: '파트너 수수료', key: 'feePartner' },
    { label: '리워드(TMOP)', key: 'reward' },
    { label: '상태', key: 'status' },
    { label: '완료 일시', key: 'completedAt' },
    { label: '취소 일시', key: 'canceledAt' },
  ];
};

export const rewardDownloadHeader = (partnerName = '파트너사'): TableHeaderInfo[] => {
  return [
    { label: '번호', key: 'number' },
    { label: '팬시 월렛 ID', key: 'email' },
    { label: `${partnerName} ID`, key: 'partnerId' },
    { label: '거래번호(팬시월렛)', key: 'orderNo', canShrink: true },
    { label: `거래번호(${partnerName})`, key: 'partnerOrderNo' },
    { label: '지급 리워드(TMOP)', key: 'reward' },
    { label: '상태', key: 'rewardStatus' },
    { label: '완료 일시', key: 'completedAt' },
    { label: '취소 일시', key: 'canceledAt' },
  ];
};

export const settlementDownloadHeader = (partnerName = '파트너사'): TableHeaderInfo[] => {
  return [
    { label: '총 사용 팬시 수량', key: 'exportAmount' },
    { label: `총 지급 ${partnerName} 수량`, key: 'importAmount' },
    { label: '리워드 팬시 수량', key: 'rewardAmount' },
    { label: '총 수수료', key: 'totalFee' },
    { label: '팬시 수수료', key: 'TmoFee' },
    { label: `${partnerName} 수수료`, key: 'partnerFee' },
  ];
};

export const settlementDetailHeader = (partnerName = '파트너사'): TableHeaderInfo[] => {
  return [
    { label: '번호', key: 'number' },
    { label: '팬시 월렛 ID', key: 'email' },
    { label: `${partnerName} ID`, key: 'partnerId' },
    { label: '거래번호(팬시월렛)', key: 'orderNo', canShrink: true },
    { label: `거래번호(${partnerName})`, key: 'partnerOrderNo' },
    { label: 'TMOP 수량', key: 'usePoint' },
    { label: `${partnerName} 수량`, key: 'getPoint' },
    { label: '수수료', key: 'fee' },
    { label: '팬시 수수료', key: 'feeTmo' },
    { label: '파트너 수수료', key: 'feePartner' },
    { label: '리워드(TMOP)', key: 'reward' },
    { label: '일시', key: 'createdAt' },
  ];
};

export const userInfoHeader = (): TableHeaderInfo[] => {
  return [
    { label: '번호', key: 'number' },
    { label: '이메일', key: 'email' },
    { label: 'id', key: 'id' },
    { label: '셀러비 uid', key: 'celebe_uid' },
    { label: '셀러비 닉네임', key: 'celebe_nickname' },
    { label: '국가', key: 'country' },
    { label: '언어', key: 'language' },
    { label: '가입일자', key: 'createdAt' },
    { label: '탈퇴여부', key: 'deletedAt' },
  ];
};

export const consumerSwapsHeader = (): TableHeaderInfo[] => {
  return [
    { label: '일시', key: 'createdAt' },
    { label: '코인A (From)', key: 'from' },
    { label: '코인B (To)', key: 'to' },
    { label: '출금수량', key: 'exportAmount' },
    { label: '입금수량', key: 'importAmount' },
    { label: '수수료', key: 'fee' },
    { label: '리워드', key: 'reward' },
    { label: '상태', key: 'status' },
  ];
};
