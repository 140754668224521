import { gql } from '@apollo/client';
import { makeCreatedAtObj } from '@utils/index';
import { Charge, Reward, TxParams } from '../types';

export const CHARGE_TX = gql`
  query partnerImportSwapsInfo(
    $pageId: Int!
    $created_at: DateTimeRange
    $swap_status: RequestSwapStatus
    $keyword: String
    $option: String
    $coinId: Int
    $perPage: Int = 10
    $page: Int = 1
  ) {
    partnerImportSwapAmount(
      partner_page_id: $pageId
      created_at: $created_at
      swap_status: $swap_status
      option: $option
      keyword: $keyword
      coin_id: $coinId
    ) {
      coinSwap {
        id
        exportCoin {
          id
          acronym
          unit
          name
        }
        importCoin {
          id
          acronym
          unit
          name
        }
      }
      total_count
      data {
        status
        count
        export_amount
        import_amount
        reward
        fee
        fee_tmo
        fee_partner
      }
    }

    partnerImportSwaps(
      partner_page_id: $pageId
      created_at: $created_at
      swap_status: $swap_status
      option: $option
      keyword: $keyword
      coin_id: $coinId
      orderBy: [{ column: CREATED_AT, order: DESC }]
      first: $perPage
      page: $page
    ) {
      paginatorInfo {
        count
        currentPage
        hasMorePages
        total
      }
      data {
        swap_hash
        partner_order_no
        email
        partner_id
        original_amount
        import_amount
        fee
        fee_tmo
        fee_partner
        reward
        status
        created_at
        canceled_at
      }
    }
  }
`;

export const USE_TX = gql`
  query partnerExportSwapsInfo(
    $pageId: Int!
    $created_at: DateTimeRange
    $swap_status: RequestSwapStatus
    $keyword: String
    $option: String
    $coinId: Int
    $perPage: Int = 10
    $page: Int = 1
  ) {
    partnerExportSwapAmount(
      partner_page_id: $pageId
      created_at: $created_at
      swap_status: $swap_status
      option: $option
      keyword: $keyword
      coin_id: $coinId
    ) {
      coinSwap {
        id
        exportCoin {
          id
          acronym
          unit
          name
        }
        importCoin {
          id
          acronym
          unit
          name
        }
      }
      total_count
      data {
        status
        count
        export_amount
        import_amount
        reward
        fee
        fee_tmo
        fee_partner
      }
    }

    partnerExportSwaps(
      partner_page_id: $pageId
      created_at: $created_at
      swap_status: $swap_status
      option: $option
      keyword: $keyword
      coin_id: $coinId
      orderBy: [{ column: CREATED_AT, order: DESC }]
      first: $perPage
      page: $page
    ) {
      paginatorInfo {
        count
        currentPage
        hasMorePages
        total
      }
      data {
        swap_hash
        partner_order_no
        email
        partner_id
        original_amount
        import_amount
        fee
        fee_tmo
        fee_partner
        reward
        status
        created_at
        canceled_at
      }
    }
  }
`;

export const REWARD_TX = gql`
  query partnerRewardInfo(
    $pageId: Int!
    $coinId: Int
    $created_at: DateTimeRange
    $keyword: String
    $reward_status: RequestRewardStatus
    $perPage: Int = 10
    $page: Int = 1
    $option: String
  ) {
    partnerRewardAmount(
      partner_page_id: $pageId
      coin_id: $coinId
      created_at: $created_at
      keyword: $keyword
      option: $option
      reward_status: $reward_status
    ) {
      total_count
      data {
        status
        count
        reward
      }
    }

    partnerRewards(
      partner_page_id: $pageId
      coin_id: $coinId
      created_at: $created_at
      keyword: $keyword
      reward_status: $reward_status
      orderBy: [{ column: CREATED_AT, order: DESC }]
      first: $perPage
      page: $page
      option: $option
    ) {
      paginatorInfo {
        count
        currentPage
        hasMorePages
        total
      }
      data {
        reward_hash
        partner_order_no
        email
        partner_id
        amount
        status
        created_at
        canceled_at
      }
    }
  }
`;

// 날짜 미설정시 query문
export const makeSwapVariables = ({
  pageId,
  coinId,
  startDate,
  endDate,
  keyword,
  status,
  option,
  perPage,
  page,
}: TxParams) => {
  return !startDate || !endDate
    ? {
        pageId,
        coinId: Number(coinId),
        keyword,
        swap_status: status || Charge.Status.전체,
        option,
        perPage,
        page,
      }
    : {
        pageId,
        coinId: Number(coinId),
        created_at: makeCreatedAtObj(startDate, endDate),
        keyword: keyword,
        swap_status: status || Charge.Status.전체,
        option: option,
        perPage: perPage,
        page,
      };
};

export const makeRewardVariables = ({
  pageId,
  coinId,
  startDate,
  endDate,
  keyword,
  status,
  option,
  perPage,
  page,
}: TxParams) => {
  return !startDate || !endDate
    ? {
        pageId,
        coinId: Number(coinId),
        keyword,
        reward_status: status || Reward.Status.전체,
        option,
        perPage,
        page,
      }
    : {
        pageId,
        coinId: Number(coinId),
        created_at: makeCreatedAtObj(startDate, endDate),
        keyword: keyword,
        reward_status: status || Reward.Status.전체,
        option: option,
        perPage: perPage,
        page,
      };
};
