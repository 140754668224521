export const initialLabels = [
  '1월',
  '2월',
  '3월',
  '4월',
  '5월',
  '6월',
  '7월',
  '8월',
  '9월',
  '10월',
  '11월',
  '12월',
];

export const initialChartData = {
  labels: initialLabels,
  datasets: [
    {
      label: '스왑 수량',
      type: 'line' as const,
      data: [0],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
      yAxisID: 'y1',
    },

    {
      label: '리워드 수량',
      type: 'bar' as const,
      data: [0],
      borderColor: 'rgba(128, 0, 128, 1)',
      backgroundColor: 'rgba(128, 0, 128, 0.5)',
      yAxisID: 'y',
    },
    {
      label: '수수료',
      type: 'bar' as const,
      data: [0],
      borderColor: 'rgba(255, 165, 0, 1)',
      backgroundColor: 'rgba(255, 165, 0, 0.5)',
      yAxisID: 'y',
    },
  ],
};

export const chartOptions: any = {
  plugins: {
    customTitle: {
      y: {
        display: true,
        text: 'Numbers',
      },
      y1: {
        display: true,
        text: 'Month',
        offsetX: 5,
        offsetY: 5,
        font: '12px Comic Sans MS',
      },
    },
  },
  // maxBarThickness: 16,
  grouped: true,
  interaction: {
    mode: 'index',
  },
  scales: {
    xAxes: {
      ticks: {
        autoSkip: true,
        maxRotation: 90,
        labelOffset: 4,
        padding: 4,
        font: {
          size: 16,
        },
      },
      grid: {
        display: false, //뒷배경 라인 없애기
      },
    },
    x: {
      display: false, //하단 라인을 없애기

      ticks: {
        display: false, //새로운tick을 만들었으니 기존의 tick을 제거
      },
    },
    y: {
      type: 'linear' as const,
      display: true,
      position: 'left' as const,
      grid: {
        drawOnChartArea: false,
      },

      title: {
        display: true,
        text: '리워드, 수수료',
        align: 'end',
        font: {
          size: 15,
        },
      },
    },
    y1: {
      type: 'linear' as const,
      display: true,
      position: 'right' as const,
      suggestedMin: 0,
      suggestedMax: 100,
      title: {
        display: true,
        align: 'end',
        text: '스왑수량',
        font: {
          size: 15,
        },
      },
    },
  },
};
