import React from 'react';
import { Flex, Select } from '@chakra-ui/react';
import { createMonthArray, createYearArray } from '@utils/index';

interface Props {
  fromYear: number;
  selectedYear: number;
  selectedMonth: number;
  onChangeYear: (value: number) => void;
  onChangeMonth: (value: number) => void;
}

const DateSelect: React.FC<Props> = ({
  fromYear,
  selectedYear,
  selectedMonth,
  onChangeYear,
  onChangeMonth,
}) => {
  const yearArray = createYearArray(fromYear);
  const monthArray = createMonthArray(selectedYear);

  return (
    <Flex gap={4} minW={72}>
      <Select
        maxW={32}
        size="md"
        onChange={(e) => {
          onChangeYear(Number(e.target.value));
        }}
        value={selectedYear}
      >
        {yearArray.map((year) => {
          return (
            <option value={year} key={year}>
              {year} 년
            </option>
          );
        })}
      </Select>
      <Select
        maxW={32}
        size="md"
        onChange={(e) => {
          onChangeMonth(Number(e.target.value));
        }}
        value={selectedMonth}
      >
        {monthArray.map((month) => {
          return (
            <option value={month} key={month}>
              {month} 월
            </option>
          );
        })}
      </Select>
    </Flex>
  );
};

export default DateSelect;
