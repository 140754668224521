import React, { useLayoutEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Flex, Icon, Menu, MenuButton, Text } from '@chakra-ui/react';
import { VscCircleFilled } from 'react-icons/vsc';
import { RiRadioButtonLine } from 'react-icons/ri';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { PRIMARY_COLOR } from '@const/style';
import { PageInfo } from '@/types/index';

interface Props {
  info: PageInfo[] | undefined;
  currentPath: string;
  title: string;
  active: boolean;
}

const MenuButtons: React.FC<Props> = ({ info, currentPath, title, active }) => {
  const [isOpen, setIsOpen] = useState(false);

  const getBg = () => {
    if (!active && isOpen) {
      return 'gray.100';
    }
    if (active) {
      return PRIMARY_COLOR;
    }
    return 'none';
  };

  useLayoutEffect(() => {
    setIsOpen(active);
  }, [active]);

  return (
    <Box whiteSpace={'nowrap'}>
      <Box onClick={() => setIsOpen((prev) => !prev)}>
        <Flex width="100%" flex={1}>
          <Menu placement="right">
            <Flex
              py={2}
              px={4}
              borderRadius={8}
              _hover={{ textDecor: 'none', backgroundColor: !active && 'gray.100' }}
              backgroundColor={getBg()}
              w="100%"
              alignSelf="center"
            >
              <MenuButton w="100%">
                <Flex align="center" gap={3} justifyContent="space-between">
                  <Text
                    fontWeight={active ? 'bold' : 'normal'}
                    fontSize="md"
                    color={active ? 'white' : 'black'}
                  >
                    {title}
                  </Text>
                  <Icon
                    as={isOpen ? FaAngleUp : FaAngleDown}
                    w={5}
                    h={5}
                    color={active ? 'white' : 'black'}
                  ></Icon>
                </Flex>
              </MenuButton>
            </Flex>
          </Menu>
        </Flex>
      </Box>
      {isOpen && (
        <Flex direction="column" w="100%" gap={1} mt={2}>
          {info?.map(({ url, name }, idx) => (
            <RouterLink key={`${name}-${idx}`} to={url} style={{ width: '100%' }}>
              <Menu>
                <MenuButton
                  w="100%"
                  h={8}
                  _hover={{ textDecor: 'none', backgroundColor: 'gray.100' }}
                  py={5}
                  borderRadius={8}
                >
                  <Flex align="center" pl={5} gap={3}>
                    <Icon
                      as={currentPath === url ? RiRadioButtonLine : VscCircleFilled}
                      w={currentPath === url ? 4 : 3}
                      h={currentPath === url ? 4 : 3}
                      color={currentPath === url ? PRIMARY_COLOR : 'gray.500'}
                    ></Icon>
                    <Text
                      color={currentPath === url ? 'black' : 'gray.500'}
                      fontWeight={currentPath === url ? 'bold' : 'normal'}
                    >
                      {name}
                    </Text>
                  </Flex>
                </MenuButton>
              </Menu>
            </RouterLink>
          ))}
        </Flex>
      )}
    </Box>
  );
};

export default MenuButtons;
