export const initialPageInfo = {
  count: 1,
  currentPage: 1,
  hasMorePages: false,
  total: 0,
};

export interface PaginatorInfo {
  count: number;
  currentPage: number;
  hasMorePages: boolean;
  total: number;
}

export enum Tables {
  Charge = 'import',
  Use = 'export',
  Reward = 'reward',
  Bill = 'bill',
  User = 'users',
}

export type SearchOptions = {
  [key in Tables]: SelectOption[];
};

export type SelectOption = {
  key: string;
  value: string;
};

export interface KeyLabel {
  key: string;
  label: string;
}

export interface PageInfo {
  id: string;
  parent_id: number;
  url: string;
  name: string;
  depth: number;
  order_by_no: number;
  is_folding_menu: boolean;
}

export interface ParentPageInfo extends PageInfo {
  parent: PageInfo;
  child?: PageInfo[];
}

export interface UserInfo {
  id: string;
  email: string;
  partnerPages: ParentPageInfo[];
  coin: CoinInfo;
}

export interface CoinInfo {
  id: string;
  acronym?: string;
  unit?: string;
  name?: string;
}

export interface SwapInfo {
  id: string;
  exportCoin: CoinInfo;
  importCoin: CoinInfo;
}

export interface GeoipInfo {
  country: string;
  city: string;
  state?: string;
  state_name?: string;
  timezone?: string;
}

export interface UserWalletInfo {
  amount: number;
  coin: CoinInfo;
  created_at: string;
  id: string;
  wallet_address: string;
}

export interface TxParams {
  pageId: number;
  coinId: string | number;
  startDate: Date | null;
  endDate: Date | null;
  keyword: string;
  status: string;
  option: string;
  perPage: number;
  page: number;
}

// type > info > data > res
// params
// enum 은 내용에 맞게

export * from './namespace';
