import { atom, selector } from 'recoil';
import { Statistics } from '../types';

export const dashboardInfoState = atom<{
  partnerTotalAmount: Statistics.TodayAmountRes[];
  createUserCoinWalletCount: Statistics.WalletCountRes[];
} | null>({
  key: 'dashboardState/dashboardInfo',
  default: null,
});

export const todayStatistics = selector({
  key: 'dashboardState/todayStatistics',
  get: ({ get }) => {
    const infos = get(dashboardInfoState);

    if (infos) {
      return infos.partnerTotalAmount;
    } else {
      return [];
    }
  },
});

export const walletStatistics = selector({
  key: 'dashboardState/walletStatistics',
  get: ({ get }) => {
    const infos = get(dashboardInfoState);

    if (infos) {
      return infos.createUserCoinWalletCount;
    } else {
      return [];
    }
  },
});
