import React from 'react';
import { Flex, Menu, MenuButton, Text, Icon } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { FaAngleRight } from 'react-icons/fa';
import { PRIMARY_COLOR } from '@const/style';

interface Props {
  title: string;
  active: boolean;
  path?: string;
  // icon?: ComponentWithAs<'svg', IconProps> | IconType;
}

const NavItem: React.FC<Props> = ({ title, active, path }) => {
  const getItem = () => {
    return (
      <Flex width="100%" flex={1} whiteSpace={'nowrap'}>
        <Menu placement="right">
          <Flex
            py={2}
            px={4}
            borderRadius={8}
            _hover={{ textDecor: 'none', backgroundColor: !active && 'gray.100' }}
            backgroundColor={active ? PRIMARY_COLOR : 'none'}
            w="100%"
            alignSelf="center"
          >
            <MenuButton w="100%">
              <Flex align="center" gap={3} justifyContent="space-between">
                <Text
                  fontWeight={active ? 'bold' : 'normal'}
                  fontSize="md"
                  color={active ? 'white' : 'black'}
                >
                  {title}
                </Text>
                <Icon as={FaAngleRight} w={5} h={5} color={active ? 'white' : 'black'}></Icon>
              </Flex>
            </MenuButton>
          </Flex>
        </Menu>
      </Flex>
    );
  };

  if (path) {
    return <RouterLink to={path}>{getItem()}</RouterLink>;
  }
  return getItem();
};

export default NavItem;
