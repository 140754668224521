import { gql } from '@apollo/client';

export const SEARCH_OPTIONS = gql`
  query partnerSearchOption($pageId: Int!) {
    partnerSearchOption(partner_page_id: $pageId) {
      import {
        coin_id
        name
        option {
          key
          value
        }
      }
      export {
        coin_id
        name
        option {
          key
          value
        }
      }
      reward {
        coin_id
        name
        option {
          key
          value
        }
      }
    }
  }
`;
