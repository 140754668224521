import { ModalProps, modalState } from '@store/modalState';
import { useRecoilState } from 'recoil';

const useModal = () => {
  const [modals, setModals] = useRecoilState(modalState);

  // close 속성과 modalKey 는 외부로 부터 받지 않고 open시에 생성한다.
  const openModal = <T extends ModalProps>(props: Omit<T, 'close' | 'modalKey'>) => {
    const modalKey = Math.random().toString(36).substring(2);

    const nextProps = {
      modalKey,
      close: () => setModals([...modals]),
      ...props,
    };

    setModals([...modals].concat(nextProps));
  };

  return { openModal };
};

export default useModal;
