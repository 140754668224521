import { SelectOption } from '@/types/index';
import { Flex, Text, Select } from '@chakra-ui/react';
import React from 'react';

interface Props {
  onChange: (value: any) => void;
  title: string;
  options: SelectOption[];
  selectedValue: string;
  w?: number | string;
  size?: string;
  maxW?: number | string;
}

const TitleSelect: React.FC<Props> = ({
  onChange,
  title,
  options = [{ key: '', value: '' }],
  w = 'auto',
  size = 'md',
  selectedValue,
  maxW,
}) => {
  const getStyles = (_size: string) => {
    if (_size === 'sm') {
      return {
        h: '32px',
        fontSize: 'sm',
      };
    } else {
      return {
        h: '40px',
        fontSize: 'md',
      };
    }
  };
  return (
    <Flex
      minW={w}
      maxW={maxW}
      alignItems="center"
      border="1px solid"
      px={2}
      h={getStyles(size).h}
      borderRadius={6}
      borderColor="gray.200"
      bg="white"
    >
      <Flex
        alignItems={'center'}
        justify="center"
        pr={4}
        borderRight="2px solid"
        borderColor="gray.200"
        minW={24}
      >
        <Text whiteSpace={'nowrap'} fontSize={getStyles(size).fontSize}>
          {title}
        </Text>
      </Flex>
      <Select
        variant="unstyled"
        ml={4}
        onChange={(e) => onChange(e.target.value)}
        pr={1}
        value={selectedValue}
        fontSize={getStyles(size).fontSize}
      >
        {options.map((option) => {
          return (
            <option key={option.key} value={option.key}>
              {option.value}
            </option>
          );
        })}
      </Select>
    </Flex>
  );
};

export default TitleSelect;
