import { gql } from '@apollo/client';

export const LOGIN_USER = gql`
  mutation adminLoginByPartner($email: String!, $password: String!) {
    adminLoginByPartner(input: { email: $email, password: $password }) {
      access_token
      expires_in
      user {
        id
        email
        coin {
          id
        }
        partnerPages {
          id
          parent_id
          url
          name
          depth
          order_by_no
          is_folding_menu
          parent {
            id
            parent_id
            url
            name
            depth
            order_by_no
            is_folding_menu
          }
        }
      }
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshToken {
    refreshToken {
      access_token
      expires_in
    }
  }
`;

export const ME = gql`
  query me {
    me {
      id
      email
      coin {
        id
      }
      partnerPages {
        id
        parent_id
        url
        name
        depth
        order_by_no
        is_folding_menu
        parent {
          id
          parent_id
          url
          name
          depth
          order_by_no
          is_folding_menu
        }
      }
    }
  }
`;
