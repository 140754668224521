import { useLayoutEffect, useState } from 'react';
import { Consumer, Tables } from '@/types/index';
import TitleSelect from '@atoms/TitleSelect';
import { Button, Flex, Input, InputGroup } from '@chakra-ui/react';
import useDateRange from '@hooks/useDateRange';
import useSearchOptions from '@hooks/useSearchOptions';
import DatePickerRow from '@articles/DatePickerRow';
import useInput from '@hooks/useInput';
import { useRecoilState } from 'recoil';
import { TableFilterWrapper } from '@atoms/table';
import { userStatusOptions } from '@const/filter';
import { currentTableInfoFamily } from '@store/family/tableFamily';
import useKeyDown, { KeyboardKey } from '@hooks/useKeyDown';

const ConsumerFilter = () => {
  const [currentTableInfo, updateTableInfo] = useRecoilState(currentTableInfoFamily(Tables.User));

  const [keyword, handleKeyword, setKeyword] = useInput('');
  const [optionStatus, setOptionStatus] = useState('');
  const [userStatus, setUserStatus] = useState('');

  const { searchOptions } = useSearchOptions(Tables.User);

  const { startDate, endDate, handleStartDate, handleEndDate } = useDateRange(
    currentTableInfo.tStartDate,
    currentTableInfo.tEndDate,
  );

  const onSearchClick = () => {
    updateTableInfo({
      tStartDate: startDate,
      tEndDate: endDate,
      tKeyword: keyword,
      tStatus: userStatus,
      tSearchOption: optionStatus,
      tCoinOption: '', //TODO: 수정필요
      tPerPage: currentTableInfo.tPerPage,
      tCurrentPage: 1,
    });
  };
  const handleKeyDown = useKeyDown(() => onSearchClick(), [KeyboardKey.enter]);

  const handleSearchOptions = (value: string) => setOptionStatus(value);
  const handleSwapStatus = (value: Consumer.Status) => setUserStatus(value);

  useLayoutEffect(() => {
    setOptionStatus(currentTableInfo.tSearchOption);
    setKeyword(currentTableInfo.tKeyword);
    setUserStatus(currentTableInfo.tStatus);
  }, [
    currentTableInfo.tKeyword,
    currentTableInfo.tSearchOption,
    currentTableInfo.tStatus,
    setKeyword,
  ]);

  return (
    <TableFilterWrapper>
      <TitleSelect
        title="상태"
        onChange={handleSwapStatus}
        options={userStatusOptions}
        maxW={64}
        selectedValue={userStatus}
      />

      <Flex>
        <DatePickerRow
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={handleStartDate}
          onEndDateChange={handleEndDate}
        />
      </Flex>

      <Flex gap={2}>
        <TitleSelect
          title="검색 조건"
          onChange={handleSearchOptions}
          options={searchOptions[Tables.User]}
          selectedValue={optionStatus}
          w={64}
        />
        <InputGroup size="md" maxW="450px">
          <Input
            type="text"
            placeholder="내용을 입력하세요"
            value={keyword}
            onChange={handleKeyword}
            onKeyDown={handleKeyDown}
          />
        </InputGroup>
        <Flex gap={2} justifyContent={'flex-end'} flex={1}>
          <Button variant="solid" colorScheme="teal" onClick={onSearchClick}>
            검색
          </Button>
          <Button variant="outline" colorScheme="teal" onClick={() => window.location.reload()}>
            초기화
          </Button>
        </Flex>
      </Flex>
    </TableFilterWrapper>
  );
};

export default ConsumerFilter;
