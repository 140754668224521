import { gql } from '@apollo/client';

export const WALLET_STATISTICS = gql`
  query partnerUserCoinWallet($pageId: Int!) {
    partnerUserCoinWallet(partner_page_id: $pageId) {
      coin_id
      today_count
      yesterday_count
      acronym
    }
  }
`;

export const MONTHLY_STATISTICS = gql`
  query coinSwapMonthlyAggregate($year: Int!, $pageId: Int!) {
    coinSwapMonthlyAggregate(year: $year, partner_page_id: $pageId) {
      coinSwap {
        type
        exportCoin {
          id
          acronym
          unit
        }
        importCoin {
          id
          acronym
          unit
        }
      }
      aggregate {
        month
        import_amount
        reward

        fee
      }
    }
  }
`;

export const DASHBOARD_STATISTICS = gql`
  query dashboard($pageId: Int!) {
    partnerTotalAmount(partner_page_id: $pageId) {
      coinSwap {
        id
        importCoin {
          name
          acronym
          symbol
          unit
        }
        exportCoin {
          name
          acronym
          symbol
          unit
        }
      }
      import {
        total_amount
        today_amount
      }
      export {
        total_amount
        today_amount
      }
      reward {
        total_amount
        today_amount
      }
      fee {
        total_fee
        total_fee_tmo
        total_fee_partner
        today_fee
        today_fee_tmo
        today_fee_partner
      }
    }

    createUserCoinWalletCount(partner_page_id: $pageId) {
      coin {
        id
        acronym
      }
      total_count
      today_count
      yesterday_count
    }
  }
`;
