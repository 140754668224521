import { Consumer } from '@/types/namespace';
import { useQuery } from '@apollo/client';
import Loading from '@atoms/Loading';
import { Box, Flex, Text } from '@chakra-ui/react';
import { AlertModalProps } from '@components/modals/AlertModal';
import { MODAL_TYPES } from '@components/modals/Modals';
import { TEAL_BORDER, TH_BG_COLOR } from '@const/style';
import { USER_DETAIL } from '@graphql/consumer';
import useModal from '@hooks/useModal';
import { Paths } from '@pages/Router';
import { addCommas } from '@utils/index';
import React from 'react';
import { GoLinkExternal } from 'react-icons/go';
import { useNavigate } from 'react-router-dom';

interface Props {
  id: string;
}

const ConsumerInfo: React.FC<Props> = ({ id }) => {
  const navigate = useNavigate();
  const { openModal } = useModal();

  const { data, loading } = useQuery<{ userByID: Consumer.UserByIDRes }>(USER_DETAIL, {
    variables: { id: id },
    onCompleted: ({ userByID }) => {
      if (!userByID) {
        openModal<AlertModalProps>({
          type: MODAL_TYPES.alert,
          title: '알림',
          message: '존재 하지 않는 유저ID 입니다.',
        });
        navigate(Paths.User);
        return;
      }
    },
  });

  const consumer = data?.userByID;

  if (loading || !consumer) return <Loading isLoading={loading} />;

  return (
    <Flex flexDir="column" flex={1} fontSize="sm" my={6}>
      <Text fontWeight={'bold'} fontSize="md" mb={1}>
        유저 정보
      </Text>
      <Flex border={TEAL_BORDER} borderRadius={4} flexDir="column">
        <Flex fontWeight="bold" bgColor={TH_BG_COLOR} borderTopRadius={4}>
          <Box flex={1} px={3} py={2}>
            이메일
          </Box>
          <Box flex={1} px={3} py={2}>
            가입일
          </Box>
          <Box flex={1} px={3} py={2}>
            탈퇴여부
          </Box>
          <Box flex={2} px={3} py={2}>
            셀러비 UID
          </Box>
          <Box flex={1} px={3} py={2}>
            셀러비 닉네임
          </Box>
        </Flex>
        <Flex>
          <Text flex={1} px={3} py={2}>
            {consumer.email}
          </Text>
          <Text flex={1} px={3} py={2}>
            {consumer.created_at}
          </Text>
          <Text flex={1} px={3} py={2}>
            {consumer.deleted_at || 'N'}
          </Text>
          <Text flex={2} px={3} py={2}>
            {consumer.celebe_uid || '-'}
          </Text>
          <Text flex={1} px={3} py={2}>
            {consumer.celebe_nickname || '-'}
          </Text>
        </Flex>
      </Flex>
      <Text fontWeight={'bold'} fontSize="md" mb={1} mt={6}>
        접속지 정보
      </Text>
      <Flex border={TEAL_BORDER} borderRadius={4} flexDir="column">
        <Flex fontWeight="bold" bgColor={TH_BG_COLOR} borderTopRadius={4}>
          <Box flex={1} px={3} py={2}>
            국가
          </Box>
          <Box flex={1} px={3} py={2}>
            도시
          </Box>
          <Box flex={1} px={3} py={2}>
            세부 지역
          </Box>
          <Box flex={2} px={3} py={2}>
            Timezone
          </Box>
        </Flex>
        <Flex>
          <Text flex={1} px={3} py={2}>
            {consumer.userGeoip?.country || '-'}
          </Text>
          <Text flex={1} px={3} py={2}>
            {consumer.userGeoip?.city || '-'}
          </Text>
          <Text flex={1} px={3} py={2}>
            {consumer.userGeoip?.state_name || '-'}
          </Text>
          <Text flex={2} px={3} py={2}>
            {consumer.userGeoip?.timezone || '-'}
          </Text>
        </Flex>
      </Flex>
      <Text fontWeight={'bold'} fontSize="md" mb={1} mt={6}>
        월렛 정보
      </Text>
      <Flex flexDir="column" border={TEAL_BORDER} borderRadius={4}>
        <Flex
          justify={'space-between'}
          px={3}
          py={2}
          fontWeight="bold"
          bgColor={TH_BG_COLOR}
          borderTopRadius={4}
        >
          <Box flex={2}>보유 코인</Box>
          <Box flex={3}>보유 수량</Box>
          <Box flex={3}>생성일</Box>
          <Box flex={7}>지갑주소</Box>
        </Flex>
        {consumer.userCoinWallets.map((el: any) => {
          return (
            <Flex key={el.id} justify={'space-between'} px={3} py={2}>
              <Box fontWeight="bold" flex={2}>
                {el.coin.acronym}
              </Box>
              <Flex flex={3} alignItems={'center'}>
                <Text fontWeight={'bold'}>{addCommas(el.amount)}</Text>
                <Text fontSize="x-small">({el.coin.unit})</Text>
              </Flex>
              <Box flex={3}>{el.created_at}</Box>
              {getWalletAddressComp(el.coin.acronym, el.wallet_address)}
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};

export default ConsumerInfo;

function getWalletAddressComp(_acronym: string, _address: string) {
  switch (_acronym) {
    case 'ETH':
      return (
        <Flex
          alignItems="center"
          flex={7}
          gap={2}
          onClick={() => window.open(`https://etherscan.io/address/${_address}`)}
          cursor="pointer"
        >
          <Text textDecor={'underline'} color="blue.500" cursor="pointer">
            {_address}
          </Text>
          <GoLinkExternal color="black" />
        </Flex>
      );
    // TODO: 티모토큰 심볼확인필요
    case 'FANC':
      return (
        <Flex
          alignItems="center"
          flex={7}
          gap={2}
          cursor="pointer"
          onClick={() =>
            window.open(
              `https://etherscan.io/token/0xbb126042235e6bd38b17744cb31a8bf4a206c045?a=${_address}`,
            )
          }
        >
          <Text textDecor={'underline'} color="blue.500" cursor="pointer">
            {_address}
          </Text>
          <GoLinkExternal color="black" />
        </Flex>
      );
    default:
      return <Box flex={7}>{_address || '-'}</Box>;
  }
}
