import { Charge } from '@/types/namespace';
import SummaryItem from '@articles/SummaryItem';
import { Flex } from '@chakra-ui/react';
import { BgWrapper } from '@layout/index';
import { summaryInfoState } from '@store/chargeTxState';
import { getSummaryTitleByStatus } from '@utils/index';
import { useRecoilValue } from 'recoil';

const ChargeTxSummary = () => {
  const summaryInfo = useRecoilValue(summaryInfoState);
  const exportCoinUnit = summaryInfo.coinSwap.exportCoin.unit;
  const importCoinUnit = summaryInfo.coinSwap.importCoin.unit;
  const exportCoinName = summaryInfo.coinSwap.exportCoin.acronym;
  const importCoinName = summaryInfo.coinSwap.importCoin.acronym;

  return (
    <Flex flexDir="column" gap={2} mb={6}>
      {summaryInfo.data.map((el: Charge.Summary) => {
        return (
          <BgWrapper key={`${el.status}-${el.count}`}>
            <Flex flexDir="row" justifyContent={'space-between'} px={5} gap={3}>
              <SummaryItem
                title={getSummaryTitleByStatus(el.status)}
                value={el.count}
                symbol="건"
                flex={1.2}
              />
              <SummaryItem
                title={`${exportCoinName} 수량`}
                value={el.export_amount}
                symbol={exportCoinUnit}
                flex={4.2}
              />
              <SummaryItem
                title={`${importCoinName} 수량`}
                value={el.import_amount}
                symbol={importCoinUnit}
                flex={4.2}
              />
              <SummaryItem flex={3.2} title="수수료" value={el.fee} symbol={importCoinUnit} />
              <SummaryItem
                flex={2.5}
                title="팬시 수수료"
                value={el.fee_tmo}
                symbol={importCoinUnit}
              />
              <SummaryItem
                flex={2.5}
                title={`${exportCoinName} 수수료`}
                value={el.fee_partner}
                symbol={importCoinUnit}
              />
              <SummaryItem flex={2} title="리워드" value={el.reward} symbol="P" />
            </Flex>
          </BgWrapper>
        );
      })}
    </Flex>
  );
};

export default ChargeTxSummary;
