import React, { useEffect, useState } from 'react';
import { Button, Flex, Icon } from '@chakra-ui/react';
import { FaAngleDoubleLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleRight } from 'react-icons/fa';
import { PRIMARY_SCHEME } from '@const/style';

interface Props {
  perPage: number;
  perList: number;
  totalLength: number;
  currentPage: number;
  handlePage: (value: number) => void;
}
const Pagination: React.FC<Props> = ({
  perPage,
  perList,
  totalLength,
  currentPage,
  handlePage,
}) => {
  const [initialPage, setInitialPage] = useState(1);
  const [hasNextList, setHasNextList] = useState(true);
  const [hasPrevList, setHasPrevList] = useState(true);

  const totalPage = Math.ceil(totalLength / perPage);
  const array = Array.from({ length: totalPage }, (_, index) => index + 1);

  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const value = e.currentTarget.value;
    const listLastPage = initialPage + perList - 1;

    if (value === 'prev' && currentPage > 1) {
      // 이전 페이지 클릭
      handlePage(Number(currentPage - 1));
      if (currentPage - 1 < initialPage) {
        setInitialPage((prev) => prev - perList);
      }
    } else if (value === 'next' && currentPage < totalPage) {
      // 다음 페이지 클릭
      handlePage(Number(currentPage + 1));
      if (currentPage + 1 > listLastPage) {
        setInitialPage((prev) => prev + perList);
      }
    } else if (value === 'nextList') {
      // 다음 리스트 클릭
      handlePage(Number(initialPage + perList));
      setInitialPage((prev) => prev + perList);
    } else if (value === 'prevList') {
      // 이전 리스트 클릭
      handlePage(Number(initialPage - perList));
      setInitialPage((prev) => prev - perList);
    } else if (!isNaN(Number(value))) {
      // 페이지 클릭
      handlePage(Number(value));
    }
  };

  // NextList, PrevList 체크
  useEffect(() => {
    const hasNextList = initialPage + perList <= totalPage;
    const hasPrevList = initialPage - perList > 0;

    setHasNextList(hasNextList);
    setHasPrevList(hasPrevList);
  }, [initialPage, perList, totalPage]);

  // currentPage 변경시 페이지네이션 시작점 체크
  useEffect(() => {
    const area = Math.ceil(currentPage / perList);
    if (area === 1) {
      setInitialPage(1);
    } else {
      setInitialPage(perList * (area - 1) + 1);
    }
  }, [currentPage, perList]);

  return (
    <Flex justifyContent="center" mt={2} gap={2} mb={8}>
      {hasPrevList && (
        <Button colorScheme="blackAlpha" size="sm" value="prevList" onClick={onClick}>
          <Icon as={FaAngleDoubleLeft} />
        </Button>
      )}
      <Button colorScheme="blackAlpha" size="sm" value="prev" onClick={onClick}>
        <Icon as={FaAngleLeft} />
      </Button>

      {array.slice(initialPage - 1, initialPage - 1 + perList).map((page, idx) => {
        return (
          <Button
            key={idx + page}
            colorScheme={page === currentPage ? PRIMARY_SCHEME : 'gray'}
            size="sm"
            value={page}
            onClick={onClick}
          >
            {page}
          </Button>
        );
      })}
      <Button colorScheme="blackAlpha" size="sm" value="next" onClick={onClick}>
        <Icon as={FaAngleRight} />
      </Button>
      {hasNextList && (
        <Button colorScheme="blackAlpha" size="sm" value="nextList" onClick={onClick}>
          <Icon as={FaAngleDoubleRight} />
        </Button>
      )}
    </Flex>
  );
};

export default Pagination;
