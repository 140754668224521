import React from 'react';
import { Text } from '@chakra-ui/react';
import { PRIMARY_COLOR } from '@const/style';

interface Props {
  text: string;
}
const SubTitle: React.FC<Props> = ({ text }) => {
  return (
    <Text fontSize="xl" borderColor={PRIMARY_COLOR} color="blackAlpha.700" fontWeight="bold" pl={3}>
      {text}
    </Text>
  );
};

export default SubTitle;
