import React from 'react';
import Card from '@articles/Card';
import { Flex, Text } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { todayStatistics } from '@store/dashBoardState';
import { Statistics } from '@/types/namespace';

const TodayStatistics = () => {
  const data = useRecoilValue(todayStatistics);

  return (
    <Flex mt={4} gap={2} flexDir="column">
      {data?.map((info: Statistics.TodayAmountRes) => {
        const coinInfo = info.coinSwap;
        const targetCoinInfo = // tmop 의 스왑대상이 되는 코인인포
          coinInfo.exportCoin.acronym === 'TMOP' ? coinInfo.importCoin : coinInfo.exportCoin;

        return (
          <Flex key={`-${coinInfo.id}`} flexDirection="column">
            <Text as="span" fontWeight={800} fontSize={14} pl={1} color={'teal.800'} mb={0.5}>
              * {targetCoinInfo.acronym}
            </Text>
            <Flex gap={2} justifyContent="space-between" overflowX={'scroll'}>
              {info.export !== null && (
                <Card
                  title="사용 수량"
                  symbol={coinInfo.exportCoin.unit}
                  todayAmount={info.export.today_amount}
                  totalAmount={info.export.total_amount}
                />
              )}
              {info.import !== null && (
                <Card
                  title="충전 수량"
                  symbol={coinInfo.importCoin.unit}
                  todayAmount={info.import.today_amount}
                  totalAmount={info.import.total_amount}
                />
              )}
              {info.fee && info.fee.total_fee !== null && (
                <Card
                  title="수수료"
                  symbol={coinInfo.importCoin.unit}
                  todayAmount={info.fee.today_fee}
                  totalAmount={info.fee.total_fee}
                />
              )}
              {info.fee && info.fee?.total_fee_tmo !== null && (
                <Card
                  title="수수료(팬시)"
                  symbol={coinInfo.importCoin.unit}
                  todayAmount={info.fee.today_fee_tmo}
                  totalAmount={info.fee.total_fee_tmo}
                />
              )}
              {info.fee && info.fee?.total_fee_partner !== null && (
                <Card
                  title="수수료(파트너)"
                  symbol={coinInfo.importCoin.unit}
                  todayAmount={info.fee.today_fee_partner}
                  totalAmount={info.fee.total_fee_partner}
                />
              )}
              {info.reward !== null && (
                <Card
                  title="리워드"
                  todayAmount={info.reward.today_amount}
                  totalAmount={info.reward.total_amount}
                />
              )}
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};

export default TodayStatistics;
