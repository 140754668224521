import { Select } from '@chakra-ui/react';
import React from 'react';

interface Props {
  handleChange: (value: number) => void;
  selectedValue: string | number;
}

const PerpageSelect: React.FC<Props> = ({ handleChange, selectedValue }) => {
  return (
    <Select
      w="auto"
      maxW={36}
      size="sm"
      value={selectedValue}
      onChange={(e) => {
        handleChange(Number(e.target.value));
      }}
    >
      <option value={15}>15개 보기</option>
      <option value={20}>20개 보기</option>
      <option value={50}>50개 보기</option>
      <option value={100}>100개 보기</option>
    </Select>
  );
};

export default PerpageSelect;
