import { gql } from '@apollo/client';
import { makeCreatedAtObj } from '@utils/index';
import { Consumer } from '../types';

export const USER_SEARCH = gql`
  query userSearch(
    $id: ID
    $email: String
    $celebe_uid: String
    $celebe_nickname: String
    $created_at: DateTimeRange
    $trashed: Trashed
    $perPage: Int = 10
    $page: Int = 1
  ) {
    userSearch(
      id: $id
      email: $email
      celebe_uid: $celebe_uid
      celebe_nickname: $celebe_nickname
      created_at: $created_at
      orderBy: [{ column: CREATED_AT, order: DESC }]
      trashed: $trashed
      first: $perPage
      page: $page
    ) {
      paginatorInfo {
        count
        currentPage
        hasMorePages
        total
      }
      data {
        id
        uuid
        email
        locale
        created_at
        deleted_at
        celebe_uid
        celebe_nickname
        userGeoip {
          country
          city
          state
          state_name
        }
      }
    }
  }
`;

export const USER_DETAIL = gql`
  query userDetail($id: ID!) {
    userByID(id: $id, trashed: WITH) {
      id
      uuid
      email
      created_at
      deleted_at
      celebe_uid
      celebe_nickname
      userGeoip {
        country
        city
        state
        state_name
        timezone
      }
      userCoinWallets {
        id
        amount
        wallet_address
        created_at
        coin {
          id
          acronym
          name
          symbol
          unit
        }
      }
    }
  }
`;

export const USER_SWAP_LIST = gql`
  query userCoinSwapSearch($perPage: Int = 10, $page: Int = 1, $id: Mixed) {
    userCoinSwapSearch(
      first: $perPage
      page: $page
      orderBy: [{ column: CREATED_AT, order: DESC }]
      where: { column: USER_ID, operator: EQ, value: $id }
    ) {
      paginatorInfo {
        count
        currentPage
        hasMorePages
        perPage
        total
      }
      data {
        id
        exportCoin {
          name
          acronym
          symbol
          unit
        }
        importCoin {
          name
          acronym
          symbol
          unit
        }
        import_eth_amount
        export_amount
        import_amount
        fee
        reward
        created_at
        status
        type
      }
    }
  }
`;

export const makeUserInfoVariables = ({
  startDate,
  endDate,
  keyword,
  status,
  option,
  perPage,
  page,
}: Consumer.Params) => {
  return !startDate || !endDate
    ? {
        trashed: status || Consumer.Status.전체,
        [option]: keyword,
        perPage,
        page,
      }
    : {
        created_at: makeCreatedAtObj(startDate, endDate),
        trashed: status || Consumer.Status.전체,
        [option]: keyword,
        perPage: perPage,
        page,
      };
};
