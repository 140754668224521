import { useState } from 'react';

interface UseDateRangeResult {
  startDate: Date | null;
  endDate: Date | null;
  handleStartDate: (value: Date | null) => void;
  handleEndDate: (value: Date | null) => void;
}

function useDateRange(
  defaultStartDate: Date | null,
  defaultEndDate: Date | null,
): UseDateRangeResult {
  const [startDate, setStartDate] = useState<Date | null>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date | null>(defaultEndDate);

  const handleStartDate = (value: Date | null) => {
    setStartDate(value);
  };

  const handleEndDate = (value: Date | null) => {
    setEndDate(value);
  };

  return {
    startDate,
    endDate,
    handleStartDate,
    handleEndDate,
  };
}

export default useDateRange;
