import { useRecoilState, useRecoilValue } from 'recoil';
import { Flex, Table, Tbody, Tr, Text } from '@chakra-ui/react';
import { Td, TableWrapper } from '@atoms/table';
import { addCommas, parseDownloadUserInfoData } from '@utils/index';
import { userInfoHeader } from '@const/table';
import { Consumer, Tables } from '@/types/index';
import PerpageSelect from '@atoms/PerpageSelect';
import XLSDownloadButton from '@atoms/XLSDownloadButton';
import { useLazyQuery } from '@apollo/client';
import {
  currentPageFamily,
  currentTableInfoFamily,
  perPageFamily,
} from '@store/family/tableFamily';
import TableHeader from '@sections/TableHeader';
import useShrinkHeader from '@hooks/useShrinkHeader';
import Pagination from '@articles/Pagination';
import { dataListState, paginatorInfoState } from '@store/consumerState';
import NoResult from '@atoms/NoResult';
import { useNavigate } from 'react-router-dom';
import { Paths } from '@pages/Router';
import { USER_SEARCH, makeUserInfoVariables } from '@graphql/consumer';

const ConsumerTable = () => {
  const navigate = useNavigate();
  const [perPage, setPerPage] = useRecoilState(perPageFamily(Tables.User));
  const [currentPage, setCurrentPage] = useRecoilState(currentPageFamily(Tables.User));

  const { tStartDate, tEndDate, tKeyword, tStatus, tSearchOption } = useRecoilValue(
    currentTableInfoFamily(Tables.User),
  );

  const [shrinkList, handleDoubleClickHeader] = useShrinkHeader();
  const dataList = useRecoilValue(dataListState);
  const paginatorInfo = useRecoilValue(paginatorInfoState);

  const [download] = useLazyQuery(USER_SEARCH);
  const onDownloadClick = async () => {
    const { data } = await download({
      variables: makeUserInfoVariables({
        startDate: tStartDate,
        endDate: tEndDate,
        keyword: tKeyword,
        status: tStatus,
        option: tSearchOption,
        perPage: paginatorInfo.total,
        page: 1,
      }),
    });

    const list = data['userSearch'].data;
    return parseDownloadUserInfoData(list);
  };

  const handleCurrentPage = (value: number) => setCurrentPage(value);
  const handlePerPage = (value: number) => {
    setPerPage(value);
    setCurrentPage(1);
  };

  return (
    <>
      <Flex justifyContent="space-between" gap={4} alignItems={'center'}>
        <PerpageSelect handleChange={handlePerPage} selectedValue={perPage} />
        <Flex alignItems="center" gap={4}>
          <Text>
            총 <b>{addCommas(paginatorInfo.total)}</b>개
          </Text>
          <XLSDownloadButton
            onDownloadClick={onDownloadClick}
            headers={userInfoHeader()}
            fileName="회원 내역"
          />
        </Flex>
      </Flex>

      <TableWrapper>
        {paginatorInfo.count === 0 && <NoResult />}
        {dataList.length !== 0 && (
          <Table size="md">
            <TableHeader
              headerInfos={userInfoHeader()}
              shrinkList={shrinkList}
              onDoubleClick={handleDoubleClickHeader}
            />
            <Tbody fontSize="sm">
              {dataList.map(
                (
                  {
                    id,
                    email,
                    locale,
                    created_at,
                    deleted_at,
                    celebe_uid,
                    celebe_nickname,
                    userGeoip,
                  }: Consumer.UserSearchData,
                  i,
                ) => {
                  const number = paginatorInfo.total - i - (currentPage - 1) * perPage;

                  if (number >= 1) {
                    return (
                      <Tr key={`${id}`} color={deleted_at ? 'gray' : 'black'}>
                        <Td color="black">{number}</Td>
                        <Td
                          textDecor={'underline'}
                          color="blue.500"
                          cursor="pointer"
                          onClick={() => navigate(`${Paths.UserDetail.replace(':id', String(id))}`)}
                        >
                          {email || '-'}
                        </Td>
                        <Td>{id}</Td>
                        <Td>{celebe_uid || '-'}</Td>
                        <Td>{celebe_nickname || '-'}</Td>
                        <Td>{userGeoip?.country || '-'}</Td>
                        <Td>{locale || '-'}</Td>
                        <Td>{created_at || '-'}</Td>
                        <Td>{deleted_at ? deleted_at : 'N'}</Td>
                      </Tr>
                    );
                  } else return null;
                },
              )}
            </Tbody>
          </Table>
        )}
      </TableWrapper>
      <Pagination
        perPage={perPage}
        perList={5}
        totalLength={paginatorInfo.total}
        currentPage={currentPage}
        handlePage={handleCurrentPage}
      />
    </>
  );
};

export default ConsumerTable;
