import { useRecoilValue } from 'recoil';
import { useLayoutEffect } from 'react';
import { Route, Routes as ReactRoutes, useLocation } from 'react-router-dom';
import { PathsSelector, useCurrentPageId } from '@store/userState';
import Layout from '@components/layout/Layout';
import FullLayout from '@components/layout/FullLayout';
import DashBoard from './dashBoard';
import Login from './login';
import ChargeTx from './transaction/charge/ChargeTx';
import UseTx from './transaction/use/UseTx';
import RewardTx from './transaction/reward/RewardTx';
import BillHistory from './billHistory';
import Consumer from './consumer';
import ConsumerDetail from './consumer/ConsumerDetail';
import { replaceNumberToStringId } from '@utils/index';

export enum Paths {
  Dashboard = '/',
  Login = '/login',

  // 거래내역 탭
  ChargeTx = '/order/import',
  UseTx = '/order/export',
  RewardTx = '/order/reward',

  // 정산내역
  Bill = '/bill',

  // 회원정보
  User = '/users',
  UserDetail = '/users/:id',
}

const Router = () => {
  const { allowedUrls } = useRecoilValue(PathsSelector);
  const { pathname } = useLocation();
  const { pageId } = useCurrentPageId();

  useLayoutEffect(() => {
    // 로그인 페이지에서는 접근권한 체크 생략
    if (pathname === Paths.Login) {
      return;
    }

    if (allowedUrls.length > 0 && !allowedUrls.includes(replaceNumberToStringId(pathname))) {
      alert('접근 권한이 없습니다.');
      window.location.href = '/';
    }
  }, [allowedUrls, pathname]);

  return (
    <ReactRoutes>
      <Route element={<Layout />}>
        <Route path={Paths.Dashboard} element={<DashBoard pageId={pageId} />} />

        {/* 거래내역 탭 */}
        <Route path={Paths.ChargeTx} element={<ChargeTx pageId={pageId} />} />
        <Route path={Paths.UseTx} element={<UseTx pageId={pageId} />} />
        <Route path={Paths.RewardTx} element={<RewardTx pageId={pageId} />} />

        {/* 정산내역 탭 */}
        <Route path={Paths.Bill} element={<BillHistory pageId={pageId} />} />

        {/* 회원목록 탭 */}
        <Route path={Paths.User} element={<Consumer pageId={pageId} />} />
        <Route path={Paths.UserDetail} element={<ConsumerDetail />} />
      </Route>
      <Route element={<FullLayout />}>
        <Route path={Paths.Login} element={<Login />} />
        <Route path="*" element={<div>404</div>} />
      </Route>
    </ReactRoutes>
  );
};

export default Router;
