import { useState } from 'react';

interface UseDateSelectResult {
  selectedYear: number;
  selectedMonth: number;
  handleYear: (value: number) => void;
  handleMonth: (value: number) => void;
}

function useDateSelect(year?: number, month?: number): UseDateSelectResult {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const [selectedYear, setSelectedYear] = useState(year || currentYear);
  const [selectedMonth, setSelectedMonth] = useState(month || currentMonth);

  const handleYear = (value: number) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    if (value === currentYear) {
      setSelectedMonth(currentMonth);
    }
    setSelectedYear(value);
  };

  const handleMonth = (value: number) => {
    setSelectedMonth(value);
  };

  return {
    selectedYear,
    selectedMonth,
    handleYear,
    handleMonth,
  };
}

export default useDateSelect;
