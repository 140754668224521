import { isBefore, parseISO, addSeconds, format } from 'date-fns';

export const getToken = () => localStorage.getItem('ACCESS_TOKEN');
export const getExpiredAt = () => localStorage.getItem('EXPIRED_AT') || '';
export const getRefreshAvailAt = () => localStorage.getItem('REFRESH_AVAIL_AT') || '';

export const isOverExpiredAt = (): boolean => {
  return isBefore(parseISO(getExpiredAt()), new Date());
};

export const setLoginInfo = (_accessToken: string, _expiredAt: number) => {
  const newExpireAt = format(addSeconds(new Date(), _expiredAt), 'yyyy-MM-dd HH:mm:ss');
  const newRefreshAvailAt = format(addSeconds(new Date(), 120), 'yyyy-MM-dd HH:mm:ss'); // 리프레시 이후 1분 내에는 리프레시 안함

  localStorage.setItem('ACCESS_TOKEN', _accessToken);
  localStorage.setItem('EXPIRED_AT', newExpireAt);
  localStorage.setItem('REFRESH_AVAIL_AT', newRefreshAvailAt);
};

export const removeLoginInfo = () => {
  localStorage.removeItem('EXPIRED_AT');
  localStorage.removeItem('ACCESS_TOKEN');
  localStorage.removeItem('REFRESH_AVAIL_AT');
};
