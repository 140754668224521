import { Reward } from '@/types/namespace';
import SummaryItem from '@articles/SummaryItem';
import { Flex } from '@chakra-ui/react';
import { BgWrapper } from '@layout/index';
import { summaryInfoState } from '@store/rewardTxState';
import { getSummaryTitleByStatus } from '@utils/index';
import { useRecoilValue } from 'recoil';

const RewardTxSummary = () => {
  const summaryInfo = useRecoilValue(summaryInfoState);

  return (
    <Flex flexDir="column" gap={2} mb={6}>
      {summaryInfo.data.map((el: Reward.Summary) => {
        return (
          <BgWrapper key={`${el.status}-${el.count}`}>
            <Flex flexDir="row" justifyContent={'left'} gap={12} px={5}>
              <SummaryItem
                title={getSummaryTitleByStatus(el.status)}
                value={el.count}
                symbol="건"
                preventAlign
              />
              <SummaryItem title="리워드(TMOP)" value={el.reward} symbol="P" preventAlign />
            </Flex>
          </BgWrapper>
        );
      })}
    </Flex>
  );
};

export default RewardTxSummary;
