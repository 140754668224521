import React from 'react';
import SummaryItem from '@articles/SummaryItem';
import { Flex } from '@chakra-ui/react';
import { BgWrapper } from '@layout/index';
import { useRecoilValue } from 'recoil';
import { summaryInfoState } from '@store/billHistoryState';
import { BillHistory } from '@/types/namespace';

const BillHistorySummary = () => {
  const summaryInfo = useRecoilValue(summaryInfoState);
  const exportCoinUnit = summaryInfo.coinSwap.exportCoin.unit;
  const importCoinUnit = summaryInfo.coinSwap.importCoin.unit;
  const exportCoinName = summaryInfo.coinSwap.exportCoin.acronym;
  const importCoinName = summaryInfo.coinSwap.importCoin.acronym;
  const data: BillHistory.Summary = summaryInfo.data[0];

  return (
    <BgWrapper mb={20}>
      <Flex flexDir="row" justifyContent={'space-between'} px={5}>
        <SummaryItem
          title={`총 ${exportCoinName} 수량`}
          value={data?.export_amount}
          symbol={exportCoinUnit}
        />
        <SummaryItem
          title={`총 ${importCoinName} 수량`}
          value={data?.import_amount}
          symbol={importCoinUnit}
        />
        <SummaryItem title="총 수수료" value={data?.fee} symbol={importCoinUnit} />
        <SummaryItem title="팬시 수수료" value={data?.fee_tmo} symbol={exportCoinUnit} />
        <SummaryItem
          title={`${importCoinName} 수수료`}
          value={data?.fee_partner}
          symbol={importCoinUnit}
        />
        <SummaryItem title="리워드" value={data?.reward} symbol="P" />
      </Flex>
    </BgWrapper>
  );
};

export default BillHistorySummary;
