import Title from '@atoms/Title';
import { Flex } from '@chakra-ui/react';
import { BgWrapper, ContentWrapper } from '@layout/index';
import RewardTxTable from './components/RewardTxTable';
import RewardTxFilter from './components/RewardTxFilter';
import RewardTxSummary from './components/RewardTxSummary';
import Loading from '@atoms/Loading';
import useSearchOptions from '@hooks/useSearchOptions';
import { Tables } from '@/types/index';
import { useRecoilState, useRecoilValue } from 'recoil';
import React from 'react';
import { currentTableInfoFamily } from '@store/family/tableFamily';
import { useQuery } from '@apollo/client';
import { REWARD_TX, makeRewardVariables } from '@graphql/transaction';
import { rewardTxInfoState } from '@store/rewardTxState';

interface Props {
  pageId: number;
}

const RewardTx: React.FC<Props> = ({ pageId }) => {
  useSearchOptions(Tables.Reward);

  const [infos, setInfos] = useRecoilState(rewardTxInfoState);
  const {
    tStartDate,
    tEndDate,
    tKeyword,
    tStatus,
    tCoinOption,
    tSearchOption,
    tPerPage,
    tCurrentPage,
  } = useRecoilValue(currentTableInfoFamily(Tables.Reward));

  useQuery(REWARD_TX, {
    variables: makeRewardVariables({
      pageId: pageId,
      coinId: tCoinOption,
      startDate: tStartDate,
      endDate: tEndDate,
      keyword: tKeyword,
      status: tStatus,
      option: tSearchOption,
      perPage: tPerPage,
      page: tCurrentPage,
    }),
    skip: pageId === 0 || !tCoinOption,
    onCompleted: (data) => setInfos(data),
  });

  const isLoading = !infos || !pageId;
  if (isLoading) return <Loading isLoading={isLoading} />;

  return (
    <Flex px={8} flexDir="column" flex={1}>
      <Title title="리워드 내역 관리" />
      <ContentWrapper>
        <RewardTxSummary />
        <BgWrapper>
          <RewardTxFilter />
          <RewardTxTable pageId={pageId} />
        </BgWrapper>
      </ContentWrapper>
    </Flex>
  );
};

export default RewardTx;
