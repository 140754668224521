import { Box, Button, Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { BasicModalProps } from '@store/modalState';
import React from 'react';

export interface ConfirmModalProps extends BasicModalProps {
  title: string;
  message: string;
  close: () => void;
  confirm: () => void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ title, message, close, confirm }) => {
  return (
    <Box>
      <Overlay onClick={close}></Overlay>
      <Content>
        <Text fontSize="lg" fontWeight="bold">
          {title}
        </Text>
        <Text>{message}</Text>
        <Flex gap={4} justify="end">
          <Button onClick={close}>Cancel</Button>
          <Button
            colorScheme="green"
            onClick={() => {
              confirm && confirm();
              close();
            }}
          >
            Confirm
          </Button>
        </Flex>
      </Content>
    </Box>
  );
};

export default ConfirmModal;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.45);
`;
const Content = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 24px;
  top: 30%;
  left: 50%;
  padding: 16px 24px;
  width: 450px;
  border-radius: 6px;
  overflow: hidden;
  background-color: white;
  transform: translate(-50%, -50%);
  z-index: 999;
`;
