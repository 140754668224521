import { atom, selector } from 'recoil';
import { PaginatorInfo, Use, initialPageInfo } from '../types';

export const useTxInfoState = atom<{
  partnerExportSwaps: Use.ExportSwapsRes;
  partnerExportSwapAmount: Use.ExportSwapAmountRes;
} | null>({
  key: 'useTxState/useTxInfo',
  default: null,
});

export const dataListState = selector<Use.Data[]>({
  key: 'useTxState/dataListState',
  get: ({ get }) => {
    const infos = get(useTxInfoState);

    if (infos) {
      return infos.partnerExportSwaps.data;
    } else {
      return [];
    }
  },
});

export const paginatorInfoState = selector<PaginatorInfo>({
  key: 'useTxState/paginatorInfoState',
  get: ({ get }) => {
    const infos = get(useTxInfoState);

    if (infos) {
      return infos.partnerExportSwaps.paginatorInfo;
    } else {
      return initialPageInfo;
    }
  },
});

export const summaryInfoState = selector<Use.ExportSwapAmountRes>({
  key: 'useTxState/summaryInfo',
  get: ({ get }) => {
    const infos = get(useTxInfoState);

    if (infos) {
      return infos.partnerExportSwapAmount;
    } else {
      return {
        total_count: 0,
        data: [],
        coinSwap: {
          id: '',
          exportCoin: {
            id: '',
            acronym: '',
            unit: '',
          },
          importCoin: {
            id: '',
            acronym: '',
            unit: '',
          },
        },
      };
    }
  },
});
