import React from 'react';
import { useParams } from 'react-router-dom';
import ConsumerInfo from './components/ConsumerInfo';
import ConsumerSwaps from './components/ConsumerSwaps';
import { BgWrapper, ContentWrapper } from '@layout/index';
import { Flex } from '@chakra-ui/react';
import Title from '@atoms/Title';
import Loading from '@atoms/Loading';

const ConsumerDetail = () => {
  const { id } = useParams();

  if (!id) {
    return <Loading isLoading={!id} />;
  }

  return (
    <Flex px={8} flexDir="column" flex={1}>
      <Title title="기본 정보" />
      <ContentWrapper>
        <BgWrapper mb={12}>
          <ConsumerInfo id={id} />
        </BgWrapper>
        <Title title="스왑 내역" />
        <BgWrapper mb={12} mt={8}>
          <ConsumerSwaps id={id} />
        </BgWrapper>
      </ContentWrapper>
    </Flex>
  );
};

export default ConsumerDetail;
