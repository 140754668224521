import { TableContainer, Flex, Th as TableHeader, Td as TableData } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const TableWrapper = styled(TableContainer)`
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
`;

export const TableFilterWrapper = styled(Flex)`
  gap: 1rem;
  margin-bottom: 2rem;
  flex-direction: column;
  padding-bottom: 2rem;
  padding-top: 1rem;
  border-bottom: 1px solid #e2e8f0;
`;

export const Th = styled(TableHeader)`
  text-align: center;
  padding: 12px 16px;
  user-select: none;
  white-space: pre-line;
  width: auto;
  min-width: 120px;
  word-break: keep-all;
`;

export const Td = styled(TableData)`
  text-align: center;
  padding: 10px;
`;
