import { CoinInfo, GeoipInfo, PaginatorInfo, SwapInfo, UserWalletInfo } from '.';

// 대시보드
export namespace Statistics {
  export interface WalletCountRes {
    coin: CoinInfo;
    today_count: number;
    yesterday_count: number;
    total_count: number;
  }

  export interface TodayAmountRes {
    coinSwap: SwapInfo;
    export: {
      today_amount: number;
      total_amount: number;
    };
    import: {
      today_amount: number;
      total_amount: number;
    };
    reward: {
      today_amount: number;
      total_amount: number;
    };
    fee: {
      today_fee: number;
      total_fee: number;
      today_fee_tmo: number;
      today_fee_partner: number;
      total_fee_tmo: number;
      total_fee_partner: number;
    };
  }

  export interface MonthlyChartRes {
    coinSwap: SwapInfo;
    aggregate: MonthlyChartData[];
  }

  export interface MonthlyChartData {
    month: number;
    import_amount: string;
    reward: string;
    fee: string;
  }

  export interface ChartInfo {
    [key: string]: { importAmounts: number[]; rewardAmounts: number[]; feeAmounts: number[] };
  }
}

// 충전내역
export namespace Charge {
  export interface ImportSwapAmountRes {
    data: Summary[];
    total_count: number;
    coinSwap: SwapInfo;
  }

  export interface ImportSwapsRes {
    data: Data[];
    paginatorInfo: PaginatorInfo;
  }

  export enum Status {
    전체 = 'ALL',
    완료 = 'COMPLETED',
    취소 = 'CANCEL',
    대기 = 'PENDING',
  }

  export interface Data {
    swap_hash: string;
    email: string;
    partner_id: string;
    original_amount: number;
    import_amount: number;
    fee: number;
    fee_tmo: number;
    fee_partner: number;
    status: string;
    created_at: string;
    canceled_at: string;
    partner_order_no: string;
  }

  export interface Summary {
    count: number;
    export_amount: number;
    fee: number;
    fee_tmo: number;
    fee_partner: number;
    import_amount: number;
    reward: number;
    status: string;
  }

  export interface Params {
    pageId: number;
    coinId: string | number;
    startDate: Date | null;
    endDate: Date | null;
    keyword: string;
    status: string;
    option: string;
    perPage: number;
    page: number;
  }
}

// 사용내역
export namespace Use {
  export interface ExportSwapAmountRes {
    data: Summary[];
    total_count: number;
    coinSwap: SwapInfo;
  }

  export interface ExportSwapsRes {
    data: Data[];
    paginatorInfo: PaginatorInfo;
  }

  export enum Status {
    전체 = 'ALL',
    완료 = 'COMPLETED',
    취소 = 'CANCEL',
    대기 = 'PENDING',
  }

  export type Summary = {
    count: number;
    export_amount: number;
    import_amount: number;
    fee: number;
    fee_tmo: number;
    fee_partner: number;
    reward: number;
    status: string;
  };

  export interface Data {
    swap_hash: string;
    email: string;
    partner_id: string;
    original_amount: number;
    import_amount: number;
    fee: number;
    fee_tmo: number;
    fee_partner: number;
    reward: number;
    status: string;
    created_at: string;
    canceled_at: string;
    partner_order_no: string;
  }

  export interface Params {
    pageId: number;
    coinId: string | number;
    startDate: Date | null;
    endDate: Date | null;
    keyword: string;
    status: string;
    option: string;
    perPage: number;
    page: number;
  }
}

// 리워드내역
export namespace Reward {
  export interface RewardAmountRes {
    data: Summary[];
    total_count: number;
  }

  export interface RewardSwapsRes {
    data: Data[];
    paginatorInfo: PaginatorInfo;
  }

  export enum Status {
    전체 = 'ALL',
    완료 = 'COMPLETED',
    취소 = 'CANCEL',
  }

  export type Summary = {
    count: number;
    reward: number;
    status: string;
  };

  export interface Data {
    amount: number;
    canceled_at: string;
    created_at: string;
    email: string;
    partner_id: string;
    partner_order_no: string;
    reward_hash: string;
    status: string;
  }

  export interface Params {
    pageId: number;
    coinId: string | number;
    startDate: Date | null;
    endDate: Date | null;
    keyword: string;
    status: string;
    option: string;
    perPage: number;
    page: number;
  }
}

// 정산내역
export namespace BillHistory {
  export interface SettlementAmountRes {
    data: Summary[];
    total_count: number;
    coinSwap: SwapInfo;
  }

  export interface SettlementsRes {
    data: Data[];
    paginatorInfo: PaginatorInfo;
  }

  export interface Data {
    swap_hash: string;
    partner_order_no: string;
    email: string;
    partner_id: string;
    original_amount: number;
    import_amount: number;
    fee: number;
    fee_tmo: number;
    fee_partner: number;
    reward: number;
    created_at: string;
  }

  export interface Summary {
    count: number;
    import_amount: number;
    export_amount: number;
    fee_tmo: number;
    fee_partner: number;
    fee: number;
    reward: number;
    status: string;
  }
}

// 회원목록
export namespace Consumer {
  export interface UserSearchRes {
    data: UserSearchData[];
    paginatorInfo: PaginatorInfo;
  }

  export interface UserByIDRes {
    celebe_nickname: string;
    celebe_uid: string;
    created_at: string;
    deleted_at: string;
    email: string;
    id: string;
    userCoinWallets: UserWalletInfo[];
    userGeoip: GeoipInfo;
    uuid: string;
  }

  export enum Status {
    전체 = 'WITH',
    회원 = 'WITHOUT',
    탈퇴회원 = 'ONLY',
  }

  export interface Params {
    startDate: Date | null;
    endDate: Date | null;
    keyword: string;
    status?: string;
    option: string;
    perPage: number;
    page: number;
  }

  export interface UserSearchData {
    id: string;
    uuid: string;
    email: string;
    locale: string;
    created_at: string;
    deleted_at: string;
    celebe_uid: string;
    celebe_nickname: string;
    userGeoip: GeoipInfo;
  }
}
