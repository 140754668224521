import Title from '@atoms/Title';
import { Box } from '@chakra-ui/react';
import DashBoardChart from './components/DashBoardChart';
import TodayStatistics from './components/TodayStatistics';
import ConnectedWalletInfo from './components/ConnectedWalletInfo';
import Loading from '@atoms/Loading';
import { useQuery } from '@apollo/client';
import { DASHBOARD_STATISTICS } from '@graphql/statistics';
import { useRecoilState } from 'recoil';
import { dashboardInfoState } from '@store/dashBoardState';
import React from 'react';

interface Props {
  pageId: number;
}

const DashBoard: React.FC<Props> = ({ pageId }) => {
  const [infos, setInfos] = useRecoilState(dashboardInfoState);

  useQuery(DASHBOARD_STATISTICS, {
    variables: { pageId: pageId },
    skip: pageId === 0,
    onCompleted: (data) => setInfos(data),
  });

  const isLoading = !infos || !pageId; // 저장된 정보가 없는경우 혹은 pageId 가 없는 경우
  if (isLoading) return <Loading isLoading={isLoading} />;

  return (
    <Box px={8} minW="900px" mb={24}>
      <Title title="투데이 현황" />
      <TodayStatistics />

      <Title title="지갑연결 현황" mt={12} />
      <ConnectedWalletInfo />

      <Title title="월별 데이터 추이" mt={12} />
      <DashBoardChart pageId={pageId} />
    </Box>
  );
};

export default DashBoard;
