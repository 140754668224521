import styled from '@emotion/styled';
import { CgSmileNone } from 'react-icons/cg';

function NoResult() {
  return (
    <NoDataWrapper>
      <CgSmileNone size={56} />
      <span>검색 결과가 없습니다...</span>
    </NoDataWrapper>
  );
}

export default NoResult;

const NoDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 72px;

  font-size: 20px;
  font-weight: bold;
`;
