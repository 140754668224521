import { useNavigate } from 'react-router-dom';
import { Button, Flex } from '@chakra-ui/react';
import { Paths } from '@pages/Router';
import { removeLoginInfo } from '@utils/index';
import useModal from '@hooks/useModal';
import { MODAL_TYPES } from '@components/modals/Modals';
import { ConfirmModalProps } from '@components/modals/ConfirmModal';

const Header = () => {
  const navigate = useNavigate();
  const { openModal } = useModal();

  const onLogoutClick = () => {
    removeLoginInfo();
    navigate(Paths.Login);
    window.location.reload();
  };

  return (
    <Flex align={'center'} justify="flex-end" px={8} py={3}>
      <Button
        colorScheme="red"
        onClick={() => {
          openModal<ConfirmModalProps>({
            type: MODAL_TYPES.confirm,
            message: '로그아웃 하시겠습니까?',
            title: 'Logout',
            confirm: onLogoutClick,
          });
        }}
      >
        Logout
      </Button>
    </Flex>
  );
};

export default Header;
