import { initialPageInfo } from '@/types/index';
import { useQuery } from '@apollo/client';
import Pagination from '@articles/Pagination';
import PerpageSelect from '@atoms/PerpageSelect';
import { TableWrapper, Td } from '@atoms/table';
import { Flex, Table, Tbody, Text, Tr } from '@chakra-ui/react';
import { TB_FONT_SIZE, T_SIZE } from '@const/style';
import { consumerSwapsHeader } from '@const/table';
import { USER_SWAP_LIST } from '@graphql/consumer';
import useShrinkHeader from '@hooks/useShrinkHeader';
import TableHeader from '@sections/TableHeader';
import { addCommas, parseSwapStatus } from '@utils/index';
import React, { useState } from 'react';

interface Props {
  id: string;
}

const ConsumerSwaps: React.FC<Props> = ({ id }) => {
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [shrinkList, handleDoubleClickHeader] = useShrinkHeader();
  const [paginatorInfo, setPaginatorInfo] = useState(initialPageInfo);
  const [dataList, setDataList] = useState([]);

  useQuery(USER_SWAP_LIST, {
    variables: { id, perPage, page: currentPage },
    onCompleted: ({ userCoinSwapSearch }) => {
      setPaginatorInfo(userCoinSwapSearch.paginatorInfo);
      setDataList(userCoinSwapSearch.data);
    },
    fetchPolicy: 'cache-first',
  });

  const handleCurrentPage = (value: number) => setCurrentPage(value);
  const handlePerPage = (value: number) => {
    setPerPage(value);
    setCurrentPage(1);
  };

  return (
    <>
      <Flex justifyContent="space-between" gap={4} alignItems={'center'} mt={8}>
        <PerpageSelect handleChange={handlePerPage} selectedValue={perPage} />
        <Flex alignItems="center" gap={4}>
          <Text>
            총 <b>{addCommas(paginatorInfo.total)}</b>개
          </Text>
        </Flex>
      </Flex>
      <TableWrapper>
        <Table size={T_SIZE}>
          <TableHeader
            headerInfos={consumerSwapsHeader()}
            shrinkList={shrinkList}
            onDoubleClick={handleDoubleClickHeader}
          />
          <Tbody fontSize={TB_FONT_SIZE}>
            {dataList.map(
              (
                {
                  exportCoin,
                  importCoin,
                  export_amount,
                  import_amount,
                  fee,
                  reward,
                  status,
                  created_at,
                }: any,
                i: number,
              ) => {
                const number = paginatorInfo.total - i - (currentPage - 1) * perPage;
                const isCanceled = status === 'CANCEL';

                if (number >= 1) {
                  return (
                    <Tr key={`${id} -${i}`} color={isCanceled ? 'gray' : 'black'}>
                      <Td>{created_at || '-'}</Td>
                      <Td>{exportCoin.name}</Td>
                      <Td>{importCoin.name}</Td>
                      <Td>
                        {addCommas(export_amount)} {exportCoin.unit}
                      </Td>
                      <Td>
                        {addCommas(import_amount)} {importCoin.unit}
                      </Td>
                      <Td>
                        {addCommas(fee) || '-'} {importCoin.unit}
                      </Td>
                      <Td>{reward ? `${addCommas(reward)} TMOP` : '-'}</Td>
                      <Td color={isCanceled ? 'red.400' : 'black'}>{parseSwapStatus(status)}</Td>
                    </Tr>
                  );
                } else return null;
              },
            )}
          </Tbody>
        </Table>
      </TableWrapper>
      <Pagination
        perPage={perPage}
        perList={5}
        totalLength={paginatorInfo.total}
        currentPage={currentPage}
        handlePage={handleCurrentPage}
      />
    </>
  );
};

export default ConsumerSwaps;
