import React, { useEffect, useLayoutEffect, useState } from 'react';
import PerpageSelect from '@atoms/PerpageSelect';
import TitleSelect from '@atoms/TitleSelect';
import { TableWrapper, Td } from '@atoms/table';
import { Button, Flex, Input, Table, Tbody, Text, Tr } from '@chakra-ui/react';
import TableHeader from '@sections/TableHeader';
import { addCommas } from '@utils/index';
import useShrinkHeader from '@hooks/useShrinkHeader';
import { settlementDetailHeader } from '@const/table';
import Pagination from '@articles/Pagination';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  currentPageFamily,
  perPageFamily,
  tCoinOptionStatusFamily,
  tKeywordFamily,
  tOptionStatusFamily,
} from '@store/family/tableFamily';
import useInput from '@hooks/useInput';
import useSearchOptions from '@hooks/useSearchOptions';
import { BillHistory, Tables } from '@/types/index';
import useKeyDown, { KeyboardKey } from '@hooks/useKeyDown';
import { PRIMARY_SCHEME, TB_FONT_SIZE, T_SIZE } from '@const/style';
import NoResult from '@atoms/NoResult';
import { dataListState, paginatorInfoState, summaryInfoState } from '@store/billHistoryState';

const BillDetailTable = () => {
  const summaryInfo = useRecoilValue(summaryInfoState);
  const importCoinName = summaryInfo.coinSwap.importCoin.acronym;
  const exportCoinUnit = summaryInfo.coinSwap.exportCoin.unit;
  const importCoinUnit = summaryInfo.coinSwap.importCoin.unit;
  const [perPage, setPerPage] = useRecoilState(perPageFamily(Tables.Bill));
  const [tKeyword, setTKeyword] = useRecoilState(tKeywordFamily(Tables.Bill));
  const [currentPage, setCurrentPage] = useRecoilState(currentPageFamily(Tables.Bill));
  const [tSearchOption, setTSearchOption] = useRecoilState(tOptionStatusFamily(Tables.Bill));
  const tCoinOption = useRecoilValue(tCoinOptionStatusFamily(Tables.Bill));
  const dataList = useRecoilValue(dataListState);
  const paginatorInfo = useRecoilValue(paginatorInfoState);

  const [searchOptionStatus, setSearchOptionStatus] = useState('');
  const [keyword, handleKeyword, setKeyword] = useInput('');
  const [shrinkList, handleDoubleClickHeader] = useShrinkHeader();
  const { searchOptions } = useSearchOptions(Tables.Bill);

  const handleCurrentPage = (value: number) => setCurrentPage(value);
  const handlePerPage = (value: number) => {
    setPerPage(value);
    setCurrentPage(1);
  };

  const onSearchClick = () => {
    setTSearchOption(searchOptionStatus);
    setTKeyword(keyword);
    setCurrentPage(1);
  };
  const handleKeyDown = useKeyDown(() => onSearchClick(), [KeyboardKey.enter]);
  const handleSearchOptions = (value: string) => setSearchOptionStatus(value);

  useLayoutEffect(() => {
    setKeyword(tKeyword);
    setSearchOptionStatus(tSearchOption);
  }, [setKeyword, tKeyword, tSearchOption]);

  return (
    <>
      <Flex justifyContent="space-between" gap={4} alignItems={'center'}>
        <Flex alignItems="center" gap={4} minW={80}>
          <PerpageSelect handleChange={handlePerPage} selectedValue={perPage} />
          <Text fontSize="sm">
            총 <b>{addCommas(paginatorInfo.total)}</b>개
          </Text>
        </Flex>
        <Flex gap={2} flex={1} justify={'flex-end'}>
          <TitleSelect
            title="검색 조건"
            onChange={handleSearchOptions}
            options={searchOptions[tCoinOption]}
            selectedValue={searchOptionStatus}
            size="sm"
          />

          <Input
            type="text"
            placeholder="내용을 입력하세요"
            value={keyword}
            onChange={handleKeyword}
            onKeyDown={handleKeyDown}
            size="sm"
            maxW="360px"
          />

          <Button size="sm" variant="solid" colorScheme={PRIMARY_SCHEME} onClick={onSearchClick}>
            검색
          </Button>
        </Flex>
      </Flex>

      <TableWrapper>
        {paginatorInfo.count === 0 && <NoResult />}
        {dataList.length !== 0 && (
          <Table size={T_SIZE}>
            <TableHeader
              headerInfos={settlementDetailHeader(importCoinName)}
              shrinkList={shrinkList}
              onDoubleClick={handleDoubleClickHeader}
            />
            <Tbody fontSize={TB_FONT_SIZE}>
              {dataList.map(
                (
                  {
                    swap_hash,
                    partner_order_no,
                    email,
                    partner_id,
                    original_amount,
                    import_amount,
                    fee,
                    fee_tmo,
                    fee_partner,
                    reward,
                    created_at,
                  }: BillHistory.Data,
                  i,
                ) => {
                  const number = paginatorInfo.total - i - (currentPage - 1) * perPage;

                  if (number >= 1) {
                    return (
                      <Tr key={`${swap_hash} -${i}`}>
                        <Td color="black">{number}</Td>
                        <Td>{email || '-'}</Td>
                        <Td>{partner_id || '-'}</Td>
                        <Td>{shrinkList.includes('orderNo') ? '...' : swap_hash || '-'}</Td>
                        <Td>{partner_order_no || '-'}</Td>
                        <Td>
                          {addCommas(original_amount)} {exportCoinUnit}
                        </Td>
                        <Td>
                          {addCommas(import_amount)} {importCoinUnit}
                        </Td>
                        <Td>
                          {addCommas(fee)} {importCoinUnit}
                        </Td>
                        <Td>
                          {addCommas(fee_tmo)} {importCoinUnit}
                        </Td>
                        <Td>
                          {addCommas(fee_partner)} {importCoinUnit}
                        </Td>
                        <Td>{addCommas(reward)} P</Td>
                        <Td>{created_at || '-'}</Td>
                      </Tr>
                    );
                  } else return null;
                },
              )}
            </Tbody>
          </Table>
        )}
      </TableWrapper>
      <Pagination
        perPage={perPage}
        perList={5}
        totalLength={paginatorInfo.total}
        currentPage={currentPage}
        handlePage={handleCurrentPage}
      />
    </>
  );
};

export default BillDetailTable;
