import { Box, Flex, Text } from '@chakra-ui/react';
import { addCommas } from '@utils/index';
import React from 'react';

interface Props {
  title: string;
  value: number;
  symbol?: string;
  flex?: number;
  preventAlign?: boolean;
}
const SummaryItem: React.FC<Props> = ({
  title,
  value,
  symbol,
  flex = 'auto',
  preventAlign = false,
}) => {
  return (
    <Box flex={preventAlign ? 'none' : flex} textAlign={preventAlign ? 'left' : 'right'}>
      <Text fontSize="sm" color="gray.500">
        {title}
      </Text>
      <Box>
        {value ? (
          <Flex alignItems={'center'} gap={1} justify={preventAlign ? 'flex-start' : 'flex-end'}>
            <Text fontSize="md" fontWeight="bold">
              {addCommas(value)}{' '}
            </Text>
            <Text fontSize="sm" color="gray.700" fontWeight={'normal'}>
              {' '}
              {symbol}
            </Text>
          </Flex>
        ) : (
          '-'
        )}
      </Box>
    </Box>
  );
};

export default SummaryItem;
