import { initialLabels } from '@const/chart';
import { SelectOption } from '@/types/index';

// 월별 객체데이터를 월별데이터로 변환
export function parseMonthlyData(obj: { [key: number]: number }) {
  let list = [];
  for (let i = 0; i < 12; i++) {
    if (obj[i + 1]) {
      list[i] = obj[i + 1];
    } else {
      list[i] = 0;
    }
  }
  return list;
}

// chart-js 형식에 맞게 차트데이터 생성
export function makeChartData(
  _importAmount: number[],
  _rewardAmount: number[],
  _feeAmount: number[],
  _labels: string[],
) {
  return {
    labels: _labels,
    datasets: [
      {
        label: '스왑 수량',
        type: 'line' as const,
        data: _importAmount,
        borderColor: '#7a9ad9',
        backgroundColor: '#7a9ad9',
        yAxisID: 'y1',
      },

      {
        label: '리워드 수량',
        type: 'bar' as const,
        data: _rewardAmount,
        borderColor: '#a9d85a',
        backgroundColor: '#a9d85a',
        yAxisID: 'y',
      },
      {
        label: '수수료',
        type: 'bar' as const,
        data: _feeAmount,
        borderColor: '#6d8642',
        backgroundColor: '#6d8642',
        yAxisID: 'y',
      },
    ],
  };
}

// 진행중인 연도인경우 해당월 까지만 라벨생성
export function makeLabel(_selectedYear: string) {
  let label: string[] = [];
  const isPrevYear = Number(new Date().getFullYear()) > Number(_selectedYear);
  if (isPrevYear) {
    label = initialLabels;
  } else {
    const labelLength = new Date().getMonth() + 1;
    for (let i = 0; i < labelLength; i++) {
      label[i] = `${i + 1}월`;
    }
  }
  return label;
}

// _startYear 부터 현재 연도까지 포함된 배열
export function getYearOptions(_startYear: number): SelectOption[] {
  let option = [];
  let currentYear = Number(new Date().getFullYear());

  const length = currentYear - _startYear;
  for (let i = 0; i <= length; i++) {
    option.push({ key: String(currentYear), value: String(currentYear) });
    currentYear--;
  }

  return option;
}
