import React, { ChangeEvent, useState } from 'react';

type InputHookReturnType<T> = [
  T,
  (event: ChangeEvent<HTMLInputElement>) => void,
  React.Dispatch<React.SetStateAction<T>>,
];

export default function useInput<T>(initialValue: T): InputHookReturnType<T> {
  const [inputValue, setInputValue] = useState(initialValue);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setInputValue(event.target.value as any);
  };

  return [inputValue, handleInputChange, setInputValue];
}
