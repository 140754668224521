import React, { useLayoutEffect, useState } from 'react';
import { Charge, Tables } from '@/types/index';
import TitleSelect from '@atoms/TitleSelect';
import { Button, Flex, Input, InputGroup } from '@chakra-ui/react';
import useDateRange from '@hooks/useDateRange';
import useSearchOptions from '@hooks/useSearchOptions';
import DatePickerRow from '@articles/DatePickerRow';
import useInput from '@hooks/useInput';
import { useRecoilState } from 'recoil';
import { TableFilterWrapper } from '@atoms/table';
import { statusOptions } from '@const/filter';
import { currentTableInfoFamily } from '@store/family/tableFamily';
import useKeyDown, { KeyboardKey } from '@hooks/useKeyDown';
import { PRIMARY_SCHEME } from '@const/style';

const ChargeFilter = () => {
  const [currentTableInfo, updateTableInfo] = useRecoilState(currentTableInfoFamily(Tables.Charge));

  const [coinOptionStatus, setCoinOptionStatus] = useState('');
  const [searchOptionStatus, setSearchOptionStatus] = useState('');
  const [swapStatus, setSwapStatus] = useState('');

  const [keyword, handleKeyword, setKeyword] = useInput('');
  const { searchOptions, coinOptions } = useSearchOptions(Tables.Charge);
  const { startDate, endDate, handleStartDate, handleEndDate } = useDateRange(
    currentTableInfo.tStartDate,
    currentTableInfo.tEndDate,
  );

  const onSearchClick = () => {
    updateTableInfo({
      tStartDate: startDate,
      tEndDate: endDate,
      tKeyword: keyword,
      tStatus: swapStatus,
      tSearchOption: searchOptionStatus,
      tCoinOption: coinOptionStatus,
      tPerPage: currentTableInfo.tPerPage,
      tCurrentPage: 1,
    });
  };
  const handleKeyDown = useKeyDown(() => onSearchClick(), [KeyboardKey.enter]);

  const handleSearchOptions = (value: string) => setSearchOptionStatus(value);
  const handleSwapStatus = (value: Charge.Status) => setSwapStatus(value);

  // 코인이 변경되면 서치옵션도 초기화 필요
  const handleCoinOptions = (value: string) => {
    const initialSearchOption = searchOptions[value][0].key;

    setCoinOptionStatus(value);
    setSearchOptionStatus(initialSearchOption);
  };

  useLayoutEffect(() => {
    setCoinOptionStatus(currentTableInfo.tCoinOption);
    setSearchOptionStatus(currentTableInfo.tSearchOption);
    setKeyword(currentTableInfo.tKeyword);
    setSwapStatus(currentTableInfo.tStatus);
  }, [
    currentTableInfo.tCoinOption,
    currentTableInfo.tSearchOption,
    currentTableInfo.tKeyword,
    currentTableInfo.tStatus,
    setKeyword,
  ]);

  return (
    <TableFilterWrapper>
      <Flex gap={4}>
        <TitleSelect
          title="상태"
          onChange={handleSwapStatus}
          options={statusOptions}
          w={60}
          selectedValue={swapStatus}
        />
        <TitleSelect
          title="스왑"
          onChange={handleCoinOptions}
          options={coinOptions}
          w={72}
          selectedValue={coinOptionStatus}
        />
      </Flex>

      <Flex>
        <DatePickerRow
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={handleStartDate}
          onEndDateChange={handleEndDate}
        />
      </Flex>

      <Flex gap={2}>
        <TitleSelect
          title="검색 조건"
          onChange={handleSearchOptions}
          options={searchOptions[coinOptionStatus]}
          selectedValue={searchOptionStatus}
          w="400px"
        />
        <InputGroup size="md" maxW="450px">
          <Input
            type="text"
            placeholder="내용을 입력하세요"
            value={keyword}
            onChange={handleKeyword}
            onKeyDown={handleKeyDown}
          />
        </InputGroup>
        <Flex gap={2} justifyContent={'flex-end'} flex={1}>
          <Button variant="solid" colorScheme={PRIMARY_SCHEME} onClick={onSearchClick}>
            검색
          </Button>
          <Button
            variant="outline"
            colorScheme={PRIMARY_SCHEME}
            onClick={() => window.location.reload()}
          >
            초기화
          </Button>
        </Flex>
      </Flex>
    </TableFilterWrapper>
  );
};

export default ChargeFilter;
