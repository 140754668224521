import { DefaultValue, atomFamily, selectorFamily } from 'recoil';

export const perPageFamily = atomFamily<number, string>({
  key: 'pageFamily/perPage',
  default: 15,
});

export const currentPageFamily = atomFamily<number, string>({
  key: 'pageFamily/currentPage',
  default: 1,
});

export const pageInfoFamily = selectorFamily({
  key: 'pageFamily/pageInfo',
  get:
    (id: string) =>
    ({ get }) => {
      const perPage = get(perPageFamily(id));
      const currentPage = get(currentPageFamily(id));
      return { perPage, currentPage };
    },
});

export const tYearFamily = atomFamily<number, string>({
  key: 'tableFamily/year',
  default: new Date().getFullYear(),
});

export const tMonthFamily = atomFamily<number, string>({
  key: 'tableFamily/month',
  default: new Date().getMonth() + 1,
});

export const tStartDateFamily = atomFamily<Date | null, string>({
  key: 'tableFamily/StartDate',
  default: null,
});

export const tEndDateFamily = atomFamily<Date | null, string>({
  key: 'tableFamily/EndDate',
  default: null,
});

export const tKeywordFamily = atomFamily<string, string>({
  key: 'tableFamily/Keyword',
  default: '',
});

export const tStatusFamily = atomFamily<string, string>({
  key: 'tableFamily/Status',
  default: '',
});

export const tOptionStatusFamily = atomFamily<string, string>({
  key: 'tableFamily/OptionStatus',
  default: '',
});

export const tCoinOptionStatusFamily = atomFamily<string, string>({
  key: 'tableFamily/CoinOptionStatus',
  default: '',
});

export const currentTableInfoFamily = selectorFamily({
  key: 'tableFamily/tableInfo',
  get:
    (id: string) =>
    ({ get }) => {
      const tStartDate = get(tStartDateFamily(id));
      const tEndDate = get(tEndDateFamily(id));
      const tKeyword = get(tKeywordFamily(id));
      const tStatus = get(tStatusFamily(id));
      const tSearchOption = get(tOptionStatusFamily(id));
      const tCoinOption = get(tCoinOptionStatusFamily(id));
      const tPerPage = get(perPageFamily(id));
      const tCurrentPage = get(currentPageFamily(id));
      return {
        tStartDate,
        tEndDate,
        tKeyword,
        tStatus,
        tSearchOption,
        tCoinOption,
        tPerPage,
        tCurrentPage,
      };
    },
  set:
    (id: string) =>
    ({ set }, params) => {
      if (!(params instanceof DefaultValue)) {
        set(tStartDateFamily(id), params.tStartDate);
        set(tEndDateFamily(id), params.tEndDate);
        set(tKeywordFamily(id), params.tKeyword);
        set(tStatusFamily(id), params.tStatus);
        set(tOptionStatusFamily(id), params.tSearchOption);
        set(tCoinOptionStatusFamily(id), params.tCoinOption);
        set(perPageFamily(id), params.tPerPage);
        set(currentPageFamily(id), params.tCurrentPage);
      }
    },
});
