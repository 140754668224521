import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Flex, Table, Tbody, Tr, Text } from '@chakra-ui/react';
import { Td, TableWrapper } from '@atoms/table';
import { addCommas, parseDownloadChargeData, parseSwapStatus } from '@utils/index';
import { chargeDownloadHeader } from '@const/table';
import { Charge, Tables } from '@/types/index';
import PerpageSelect from '@atoms/PerpageSelect';
import XLSDownloadButton from '@atoms/XLSDownloadButton';
import { useLazyQuery } from '@apollo/client';
import { CHARGE_TX, makeSwapVariables } from '@graphql/transaction';
import {
  currentPageFamily,
  currentTableInfoFamily,
  perPageFamily,
} from '@store/family/tableFamily';
import TableHeader from '@sections/TableHeader';
import useShrinkHeader from '@hooks/useShrinkHeader';
import Pagination from '@articles/Pagination';
import { TB_FONT_SIZE, T_SIZE } from '@const/style';
import NoResult from '@atoms/NoResult';
import { dataListState, paginatorInfoState, summaryInfoState } from '@store/chargeTxState';

interface Props {
  pageId: number;
}

const ChargeTxTable: React.FC<Props> = ({ pageId }) => {
  const summaryInfo = useRecoilValue(summaryInfoState);
  const exportCoinName = summaryInfo.coinSwap.exportCoin.acronym;
  const exportCoinUnit = summaryInfo.coinSwap.exportCoin.unit;
  const importCoinUnit = summaryInfo.coinSwap.importCoin.unit;
  const [perPage, setPerPage] = useRecoilState(perPageFamily(Tables.Charge));
  const [currentPage, setCurrentPage] = useRecoilState(currentPageFamily(Tables.Charge));
  const { tStartDate, tEndDate, tKeyword, tStatus, tCoinOption, tSearchOption } = useRecoilValue(
    currentTableInfoFamily(Tables.Charge),
  );

  const [shrinkList, handleDoubleClickHeader] = useShrinkHeader();
  const dataList = useRecoilValue(dataListState);
  const paginatorInfo = useRecoilValue(paginatorInfoState);

  const [download] = useLazyQuery(CHARGE_TX);
  const onDownloadClick = async () => {
    const { data } = await download({
      variables: makeSwapVariables({
        pageId,
        coinId: tCoinOption,
        startDate: tStartDate,
        endDate: tEndDate,
        keyword: tKeyword,
        status: tStatus,
        option: tSearchOption,
        perPage: paginatorInfo.total,
        page: 1,
      }),
    });

    const list = data['partnerImportSwaps'].data;
    return parseDownloadChargeData(list);
  };

  const handleCurrentPage = (value: number) => setCurrentPage(value);
  const handlePerPage = (value: number) => {
    setPerPage(value);
    setCurrentPage(1);
  };

  return (
    <>
      <Flex justifyContent="space-between" gap={4} alignItems={'center'}>
        <PerpageSelect handleChange={handlePerPage} selectedValue={perPage} />
        <Flex alignItems="center" gap={4}>
          <Text>
            총 <b>{addCommas(paginatorInfo.total)}</b>개
          </Text>
          <XLSDownloadButton
            onDownloadClick={onDownloadClick}
            headers={chargeDownloadHeader(exportCoinName)}
            fileName="충전 내역"
          />
        </Flex>
      </Flex>

      <TableWrapper>
        {paginatorInfo.count === 0 && <NoResult />}
        {dataList.length !== 0 && (
          <Table size={T_SIZE}>
            <TableHeader
              headerInfos={chargeDownloadHeader(exportCoinName)}
              shrinkList={shrinkList}
              onDoubleClick={handleDoubleClickHeader}
            />
            <Tbody fontSize={TB_FONT_SIZE}>
              {dataList.map(
                (
                  {
                    swap_hash,
                    email,
                    partner_id,
                    original_amount,
                    import_amount,
                    fee,
                    fee_tmo,
                    fee_partner,
                    status,
                    created_at,
                    canceled_at,
                    partner_order_no,
                  }: Charge.Data,
                  i,
                ) => {
                  const number = paginatorInfo.total - i - (currentPage - 1) * perPage;
                  const isCanceled = status === Charge.Status.취소;

                  if (number >= 1) {
                    return (
                      <Tr key={`${swap_hash} -${i}`} color={isCanceled ? 'gray' : 'black'}>
                        <Td color="black">{number}</Td>
                        <Td>{email || '-'}</Td>
                        <Td>{partner_id || '-'}</Td>
                        <Td>{shrinkList.includes('orderNo') ? '...' : swap_hash || '-'}</Td>
                        <Td>{partner_order_no || '-'}</Td>
                        <Td>
                          {addCommas(original_amount)} {exportCoinUnit}
                        </Td>
                        <Td>
                          {addCommas(import_amount)} {importCoinUnit}
                        </Td>
                        <Td>
                          {addCommas(fee)} {importCoinUnit}
                        </Td>
                        <Td>
                          {addCommas(fee_tmo)} {importCoinUnit}
                        </Td>
                        <Td>
                          {addCommas(fee_partner)} {importCoinUnit}
                        </Td>
                        <Td color={isCanceled ? 'red.400' : 'black'}>{parseSwapStatus(status)}</Td>
                        <Td>{created_at || '-'}</Td>
                        <Td>{canceled_at || '-'}</Td>
                      </Tr>
                    );
                  } else return null;
                },
              )}
            </Tbody>
          </Table>
        )}
      </TableWrapper>

      <Pagination
        perPage={perPage}
        perList={5}
        totalLength={paginatorInfo.total}
        currentPage={currentPage}
        handlePage={handleCurrentPage}
      />
    </>
  );
};

export default ChargeTxTable;
