import React from 'react';
import { modalState } from '@store/modalState';
import { useRecoilValue } from 'recoil';
import ConfirmModal, { ConfirmModalProps } from './ConfirmModal';
import AlertModal, { AlertModalProps } from './AlertModal';

export const MODAL_TYPES = {
  confirm: 'confirmModal',
  alert: 'alertModal',
};

const Modals = () => {
  const modals = useRecoilValue(modalState);

  if (modals.length === 0) {
    return null;
  }

  return (
    <div id="recoil-modal">
      {modals.map((modal) => {
        switch (modal.type) {
          case MODAL_TYPES.confirm:
            return <ConfirmModal key={modal.modalKey} {...(modal as ConfirmModalProps)} />;
          case MODAL_TYPES.alert:
            return <AlertModal key={modal.modalKey} {...(modal as AlertModalProps)} />;
          default:
            return null;
        }
      })}
    </div>
  );
};

export default Modals;
