import React from 'react';
import { Images } from '@assets/images';
import styled from '@emotion/styled';

interface Props {
  isLoading: boolean;
}

const Loading: React.FC<Props> = ({ isLoading }) => {
  return (
    <Container>
      {isLoading && (
        <div className="wrap">
          <img src={Images.loadingImg} alt="" />
        </div>
      )}
    </Container>
  );
};

export default Loading;

const Container = styled.div`
  .wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 99999999999;
    img {
      height: 60px;
    }
  }
`;
