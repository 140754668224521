import { Box, Text } from '@chakra-ui/react';
import { TEAL_BORDER } from '@const/style';
import { addCommas } from '@utils/index';
import React from 'react';

interface Props {
  title: string;
  symbol?: string;
  todayAmount?: number;
  totalAmount?: number;
}

const Card: React.FC<Props> = ({ title, symbol = 'P', todayAmount = 0, totalAmount = 0 }) => {
  return (
    <Box px={4} py={3} minW={44} borderRadius={4} flex={1} border={TEAL_BORDER} bg={'white'}>
      <Text fontWeight="bold" mb={4}>
        {title}
      </Text>
      <Text fontSize="md" fontWeight="extrabold" textAlign="right">
        {addCommas(todayAmount)} {symbol}
      </Text>
      <Text fontSize="sm" textAlign="right">
        {`누적 ${title}`}: {addCommas(totalAmount)}
      </Text>
    </Box>
  );
};

export default Card;
