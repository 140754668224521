import { ChakraProvider } from '@chakra-ui/react';
import theme from '@style/theme';
import Router from '@pages/Router';
import LoginHandler from '@services/LoginHandler';
import Modals from '@components/modals/Modals';

export const App = () => {
  // UpdateChecker();
  LoginHandler();

  return (
    <ChakraProvider theme={theme}>
      <Modals />
      <Router />
    </ChakraProvider>
  );
};
