import { SelectOption } from '../types';

export const statusOptions: SelectOption[] = [
  { key: 'ALL', value: '전체' },
  { key: 'COMPLETED', value: '완료' },
  { key: 'PENDING', value: '대기' },
  { key: 'CANCEL', value: '취소' },
];

export const userStatusOptions: SelectOption[] = [
  { key: 'WITH', value: '전체' },
  { key: 'WITHOUT', value: '회원' },
  { key: 'ONLY', value: '탈퇴회원' },
];

export const userInfoSearchOptions: SelectOption[] = [
  { key: 'email', value: '이메일' },
  { key: 'celebe_uid', value: '셀러비 UID' },
  { key: 'celebe_nickname', value: '셀러비 닉네임' },
  { key: 'id', value: 'ID' },
];
