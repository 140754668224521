import { useState } from 'react';

type ShrinkHeaderReturnType = [string[], (key: string) => void];

export default function useShrinkHeader(): ShrinkHeaderReturnType {
  const [shrinkList, setShrinkList] = useState<string[]>([]);

  const handleDoubleClickHeader = (_key: string) => {
    setShrinkList((prev: string[]) => {
      if (prev.includes(_key)) {
        return prev.filter((key) => key !== _key);
      } else {
        return prev.concat(_key);
      }
    });
  };

  return [shrinkList, handleDoubleClickHeader];
}
