import { gql } from '@apollo/client';

export const BILL_HISTORY = gql`
  query partnerSettlementInfo(
    $pageId: Int!
    $coinId: Int
    $year: Int!
    $month: Int!
    $keyword: String
    $perPage: Int = 10
    $page: Int = 1
    $option: String
  ) {
    partnerSettlementAmount(
      partner_page_id: $pageId
      year: $year
      month: $month
      coin_id: $coinId
    ) {
      coinSwap {
        id
        exportCoin {
          id
          acronym
          unit
          name
        }
        importCoin {
          id
          acronym
          unit
          name
        }
      }
      total_count
      data {
        status
        count
        export_amount
        import_amount
        reward
        fee
        fee_tmo
        fee_partner
      }
    }

    partnerSettlements(
      partner_page_id: $pageId
      coin_id: $coinId
      year: $year
      month: $month
      option: $option
      keyword: $keyword
      page: $page
      first: $perPage
    ) {
      paginatorInfo {
        count
        currentPage
        hasMorePages
        perPage
        total
      }
      data {
        swap_hash
        partner_order_no
        email
        partner_id
        original_amount
        import_amount
        fee
        fee_tmo
        fee_partner
        reward
        created_at
      }
    }
  }
`;
