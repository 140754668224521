import { Button, Flex, Input, InputGroup, Text, InputLeftAddon } from '@chakra-ui/react';
import { PRIMARY_SCHEME } from '@const/style';
import { format } from 'date-fns';
import React, { useLayoutEffect, useState } from 'react';

interface Props {
  startDate: Date | null;
  endDate: Date | null;
  onStartDateChange: (e: Date | null) => void;
  onEndDateChange: (e: Date | null) => void;
}

const DatePickerRow: React.FC<Props> = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
}) => {
  const [dateRangeStatus, setDateRangeStatus] = useState('ALL');

  const onAllDateClick = () => {
    onStartDateChange(null);
    onEndDateChange(null);
  };

  const onThisMonthClick = () => {
    const thisYear = new Date().getFullYear();
    const thisMonth = new Date().getMonth();
    const thisMonthFirstDay = new Date(thisYear, thisMonth, 1);
    const thisMonthLastDay = new Date(thisYear, thisMonth + 1, 0);

    onStartDateChange(thisMonthFirstDay);
    onEndDateChange(thisMonthLastDay);
  };

  const onLastMonthClick = () => {
    const thisYear = new Date().getFullYear();
    const thisMonth = new Date().getMonth();
    const lastMonthFirstDay = new Date(thisYear, thisMonth - 1, 1);
    const lastMonthLastDay = new Date(thisYear, thisMonth, 0);

    onStartDateChange(lastMonthFirstDay);
    onEndDateChange(lastMonthLastDay);
  };

  useLayoutEffect(() => {
    const thisYear = new Date().getFullYear();
    const thisMonth = new Date().getMonth();
    const thisMonthFirstDay = new Date(thisYear, thisMonth, 1);
    const thisMonthLastDay = new Date(thisYear, thisMonth + 1, 0);
    const lastMonthFirstDay = new Date(thisYear, thisMonth - 1, 1);
    const lastMonthLastDay = new Date(thisYear, thisMonth, 0);

    if (startDate === null && endDate === null) {
      setDateRangeStatus('ALL');
    } else if (startDate === null || endDate === null) {
      setDateRangeStatus('SELECT');
    } else if (
      format(startDate as Date, 'yyyy-MM-dd') === format(thisMonthFirstDay, 'yyyy-MM-dd') &&
      format(endDate as Date, 'yyyy-MM-dd') === format(thisMonthLastDay, 'yyyy-MM-dd')
    ) {
      setDateRangeStatus('THIS_MONTH');
    } else if (
      format(startDate as Date, 'yyyy-MM-dd') === format(lastMonthFirstDay, 'yyyy-MM-dd') &&
      format(endDate as Date, 'yyyy-MM-dd') === format(lastMonthLastDay, 'yyyy-MM-dd')
    ) {
      setDateRangeStatus('LAST_MONTH');
    } else {
      setDateRangeStatus('SELECT');
    }
  }, [startDate, endDate]);

  return (
    <Flex
      alignItems="center"
      border="1px solid"
      pl={2}
      pr={2}
      h="40px"
      borderRadius={6}
      borderColor="gray.200"
    >
      <Flex
        alignItems={'center'}
        justify="center"
        pr={4}
        borderRight="2px solid"
        borderColor="gray.200"
        minW={24}
      >
        <Text whiteSpace={'nowrap'}>검색 기간</Text>
      </Flex>
      <Flex gap={2} ml={8}>
        <Button
          variant={dateRangeStatus === 'ALL' ? 'solid' : 'outline'}
          size="sm"
          colorScheme={PRIMARY_SCHEME}
          onClick={onAllDateClick}
        >
          전체
        </Button>
        <Button
          variant={dateRangeStatus === 'THIS_MONTH' ? 'solid' : 'outline'}
          size="sm"
          colorScheme={PRIMARY_SCHEME}
          onClick={onThisMonthClick}
        >
          이번 달
        </Button>
        <Button
          variant={dateRangeStatus === 'LAST_MONTH' ? 'solid' : 'outline'}
          size="sm"
          colorScheme={PRIMARY_SCHEME}
          onClick={onLastMonthClick}
        >
          지난 달
        </Button>
      </Flex>
      <InputGroup ml={8} mr={3} size="sm">
        <InputLeftAddon children="시작일" fontSize="md" />
        <Input
          type="date"
          value={startDate ? format(startDate, 'yyyy-MM-dd') : ''}
          onChange={(e) => onStartDateChange(new Date(e.target.value))}
          maxW={44}
          size="sm"
          fontSize="md"
          max={endDate ? format(endDate, 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd')}
        />
      </InputGroup>
      ~
      <InputGroup ml={3} size="sm">
        <InputLeftAddon children="종료일" fontSize="md" />
        <Input
          type="date"
          value={endDate ? format(endDate, 'yyyy-MM-dd') : ''}
          onChange={(e) => onEndDateChange(new Date(e.target.value))}
          maxW={44}
          size="sm"
          fontSize="md"
          max={format(new Date(), 'yyyy-MM-dd')}
          min={startDate ? format(startDate, 'yyyy-MM-dd') : ''}
        />
      </InputGroup>
    </Flex>
  );
};

export default DatePickerRow;
